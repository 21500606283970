import Tippy from "@tippyjs/react";

const TipMessage = (props) => {
  return (
    <Tippy
      visible={props.visible}
      placement="right"
      animation={false}
      popperOptions={{
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["top", "bottom"],
            },
          },
        ],
      }}
      content={props.content}
    >
      {props.children}
    </Tippy>
  );
};

export default TipMessage;
