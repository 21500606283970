import * as svgFiles from "inline:../../../resources/svg/*";
const SVG = (props) => {
  const { src: svgFile, label, className } = props;
  // create a temporary element and append SVG file contents to get the <svg> inner HTML
  const placeholder = document.createElement("div");
  placeholder.innerHTML = svgFiles[`${svgFile}.svg`];
  const svgElement = placeholder.querySelector("svg");
  let svgInner = svgElement?.innerHTML;
  if (label) svgInner += `<title>${label}</title>`;

  let svgProps = {
    ...props,
    xmlns: "http://www.w3.org/2000/svg",
    title: label,
    viewBox: svgElement?.getAttribute("viewBox"),
    className: className ? `svg ${className}` : "svg",
    dangerouslySetInnerHTML: { __html: svgInner },
  };
  delete svgProps.svgFile;
  delete svgProps.label;

  return <svg {...svgProps} />;
};

export default SVG;
