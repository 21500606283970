[
  {
    "key": "name",
    "label": "Full name",
    "mapToPath": "name",
    "category": "Personal"
  },
  {
    "key": "firstname",
    "label": "First name",
    "mapToPath": "details.firstName",
    "category": "Personal"
  },
  {
    "key": "middle_name",
    "label": "Middle name",
    "mapToPath": "details.middleName",
    "category": "Personal"
  },
  {
    "key": "surname",
    "label": "Surname",
    "mapToPath": "details.surname",
    "category": "Personal"
  },
  {
    "key": "address",
    "label": "Address",
    "mapToPath": "details.address",
    "category": "Personal"
  },
  {
    "key": "address_2",
    "label": "Address 2",
    "mapToPath": "details.address2",
    "category": "Personal"
  },
  {
    "key": "address_3",
    "label": "Address 3",
    "mapToPath": "details.address3",
    "category": "Personal"
  },
  {
    "key": "address_4",
    "label": "Address 4",
    "mapToPath": "details.address4",
    "category": "Personal"
  },
  {
    "key": "town",
    "label": "Town",
    "mapToPath": "details.town",
    "category": "Personal"
  },
  {
    "key": "county",
    "label": "County",
    "mapToPath": "details.county",
    "category": "Personal"
  },
  {
    "key": "postcode",
    "label": "Postcode",
    "mapToPath": "details.postcode",
    "category": "Personal"
  },
  {
    "key": "salutation",
    "label": "Salutation",
    "mapToPath": "details.salutation",
    "category": "Personal"
  },
  {
    "key": "known_as",
    "label": "Known as",
    "mapToPath": "details.knownAs",
    "category": "Personal"
  },
  {
    "key": "dob",
    "label": "Date of birth",
    "mapToPath": "details.dob",
    "category": "Personal"
  },
  {
    "key": "ni_number",
    "label": "NI number",
    "mapToPath": "employment.niNumber",
    "category": "Employment"
  },
  {
    "key": "marital_status",
    "label": "Marital status",
    "mapToPath": "details.maritalStatus",
    "category": "Employment"
  },
  {
    "key": "relationships",
    "label": "Relationships at work",
    "mapToPath": "details.relationships",
    "category": "Employment"
  },
  {
    "key": "gender",
    "label": "Gender",
    "mapToPath": "details.gender",
    "category": "Personal"
  },
  {
    "key": "email",
    "label": "Email address",
    "mapToPath": "details.email",
    "category": "Personal"
  },
  {
    "key": "additional_email",
    "label": "Additional email address",
    "mapToPath": "details.additionalEmail",
    "category": "Personal"
  },
  {
    "key": "website",
    "label": "Website",
    "mapToPath": "details.website",
    "category": "Personal"
  },
  {
    "key": "tel",
    "label": "Telephone number",
    "mapToPath": "details.tel",
    "category": "Personal"
  },
  {
    "key": "mobile",
    "label": "Telephone number (mobile)",
    "mapToPath": "details.telMobilePersonal",
    "category": "Personal"
  },
  {
    "key": "bank",
    "label": "Bank - bank name",
    "mapToPath": "bank.bank",
    "category": "Bank details"
  },
  {
    "key": "account_name",
    "label": "Bank - account name",
    "mapToPath": "bank.name",
    "category": "Bank details"
  },
  {
    "key": "account_no",
    "label": "Bank - account number",
    "mapToPath": "bank.acNo",
    "category": "Bank details"
  },
  {
    "key": "sort_code",
    "label": "Bank - sort code",
    "mapToPath": "bank.sortCode",
    "category": "Bank details"
  },
  {
    "key": "bank_ref",
    "label": "Bank - reference",
    "mapToPath": "bank.ref",
    "category": "Bank details"
  },
  {
    "key": "employment_status",
    "label": "Employment status",
    "mapToPath": "employment.status",
    "category": "Employment"
  },
  {
    "key": "employment_type",
    "label": "Employment type",
    "mapToPath": "employment.type",
    "category": "Employment"
  },
  {
    "key": "department",
    "label": "Department",
    "mapToPath": "employment.department",
    "category": "Employment"
  },
  {
    "key": "job_title",
    "label": "Job title",
    "mapToPath": "employment.jobTitle",
    "category": "Employment"
  },
  {
    "key": "salary_hourly",
    "label": "Salary / Hourly",
    "mapToPath": "employment.salaryHourly",
    "category": "Employment"
  },
  {
    "key": "salary",
    "label": "Salary",
    "mapToPath": "employment.salary",
    "category": "Employment"
  },
  {
    "key": "rate",
    "label": "Rate",
    "mapToPath": "employment.rate",
    "category": "Employment"
  },
  {
    "key": "start_date",
    "label": "Start date",
    "mapToPath": "employment.began",
    "category": "Employment"
  },
  {
    "key": "end_date",
    "label": "End date",
    "mapToPath": "employment.end",
    "category": "Employment"
  },
  {
    "key": "end_reason",
    "label": "Employment end - reason",
    "mapToPath": "employment.endReason",
    "category": "Employment"
  },
  {
    "key": "end_details",
    "label": "Employment end - details",
    "mapToPath": "employment.endDetails",
    "category": "Employment"
  },
  {
    "key": "end_notes",
    "label": "Employment end - notes",
    "mapToPath": "employment.endNotes",
    "category": "Employment"
  },
  {
    "key": "place_of_work",
    "label": "Place of work",
    "mapToPath": "employment.placeOfWork",
    "category": "Employment"
  },
  {
    "key": "work_pattern",
    "label": "Work pattern",
    "mapToPath": "employment.regularDays",
    "category": "Employment"
  },
  {
    "key": "work_pattern_details",
    "label": "Work pattern details",
    "mapToPath": "employment.workPatternDetails",
    "category": "Employment"
  },
  {
    "key": "hours_per_week",
    "label": "Hours per week",
    "mapToPath": "employment.hoursPerWeek",
    "category": "Employment"
  },
  {
    "key": "emergency_name",
    "label": "Emergency contact name",
    "mapToPath": "emergencyContacts.emergencyName",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_relationship",
    "label": "Emergenct contact relationship",
    "mapToPath": "emergencyContacts.emergencyRelationship",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_contact_number",
    "label": "Emergency contact number",
    "mapToPath": "emergencyContacts.emergencyTel",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_contact_number_alt",
    "label": "Emergency contact number (alternative)",
    "mapToPath": "emergencyContacts.emergencyTelAlt",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_email",
    "label": "Emergency contact email",
    "mapToPath": "emergencyContacts.emergencyEmail",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_address",
    "label": "Emergency contact address",
    "mapToPath": "emergencyContacts.emergencyAddress",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_address_2",
    "label": "Emergency contact address line 2",
    "mapToPath": "emergencyContacts.emergencyAddress2",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_postcode",
    "label": "Emergency contact address postcode",
    "mapToPath": "emergencyContacts.emergencyPostcode",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_name-2",
    "label": "Emergency contact 2 name",
    "mapToPath": "emergencyContacts.emergency2Name",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_relationship-2",
    "label": "Emergenct contact 2 relationship",
    "mapToPath": "emergencyContacts.emergency2Relationship",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_contact_number-2",
    "label": "Emergency contact 2 number",
    "mapToPath": "emergencyContacts.emergency2Tel",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_contact_number_alt-2",
    "label": "Emergency contact 2 number (alternative)",
    "mapToPath": "emergencyContacts.emergency2TelAlt",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_email-2",
    "label": "Emergency contact 2 email",
    "mapToPath": "emergencyContacts.emergency2Email",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_address-2",
    "label": "Emergency contact 2 address",
    "mapToPath": "emergencyContacts.emergency2Address",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_address_2-2",
    "label": "Emergency contact 2 address line 2",
    "mapToPath": "emergencyContacts.emergency2Address2",
    "category": "Emergency contacts"
  },
  {
    "key": "emergency_postcode-2",
    "label": "Emergency contact 2 address postcode",
    "mapToPath": "emergencyContacts.emergency2Postcode",
    "category": "Emergency contacts"
  }
]
