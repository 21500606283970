import("../../../scss/components/printButton.scss");
import SVG from "./svg";

const PrintButton = ({ className, onClick }) => {
  const buttonClass = "print-button" + (className ? " " + className : "");
  return (
    <button
      className={buttonClass}
      aria-label="Print"
      type="button"
      onClick={onClick}
    >
      <SVG src="printer" className="icon" label="Print" />
    </button>
  );
};

export default PrintButton;
