import SVG from "./svg";
import utils from "../../utilities";
import { useFetchResources } from "../hooks/fetch/useFetchResources";
import useStore from "../hooks/useStore";
import "../../../scss/components/fileLibraryLinks.scss";
const FileLibraryLinks = ({ id, allowDownload = true }) => {
  const authToken = useStore((state) => state.authToken);
  const clientRef = useStore((state) => state.getClientRef());
  const { data: files, isLoading } = useFetchResources({
    type: "files",
    account: clientRef,
    refetch: allowDownload,
  });
  const filteredFiles = files?.filter((file) => file.folder?.folderId === id);

  const doDownload = (file) => {
    if (!allowDownload) return;
    fetch(`${process.env.API_URL}/file/${file?.slug}?account=${clientRef}`, {
      method: "GET",
      headers: {
        "x-auth-token": authToken,
      },
    })
      .then(async (res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("target", "_blank");
        a.setAttribute("download", file?.fileName);
        a.href = url;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="file-library-links">
      {filteredFiles?.map((file) => {
        return (
          <button
            type="button"
            data-can-download={allowDownload}
            aria-label="Download file"
            onClick={() => {
              doDownload(file);
            }}
          >
            <SVG src={utils.getMimeIcon(file.mime)} label={file.description} />
            <div>{file.fileName}</div>
          </button>
        );
      })}
    </div>
  );
};

export default FileLibraryLinks;
