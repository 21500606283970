import { useState, useRef, useEffect } from "react";
import { Tag, Cross } from "akar-icons";
import { confirmDialog } from "primereact/confirmdialog";

import "../../../scss/components/tags.scss";

const Tags = ({
  className,
  tags: tagsInit,
  selectedTags: selectedTagsInit,
  multiple = false,
  canChange = true,
  onChangeTags = null,
  onTagCreate = null,
  onTagDelete = null,
}) => {
  const [showingTagSelection, setShowingTagSelection] = useState(false);
  const [selectedTags, setSelectedTags] = useState(selectedTagsInit);
  useEffect(() => {
    // if (selectedTagsInit) {
    setSelectedTags(selectedTagsInit);
    // }
  }, [selectedTagsInit]);
  const [tags, setTags] = useState(tagsInit);
  const [busy, setBusy] = useState(false);
  const tagSelect = useRef(null);
  const tagTrigger = useRef(null);
  const tagInput = useRef(null);
  useEffect(() => {
    if (tagSelect && showingTagSelection) {
      tagSelect.current?.focus();
    }
  }, [showingTagSelection]);

  useEffect(() => {
    if (tagsInit) {
      setTags(tagsInit);
      setBusy(false);
      if (tagInput.current) tagInput.current.value = "";
      showingTagSelection && tagSelect.current?.focus();
    }
  }, [tagsInit]);

  const selectedIds = [];
  selectedTags &&
    selectedTags.map((tag, key) => {
      selectedIds.push(tag.value);
    });

  const doToggleTag = (label, value) => {
    let newSelectedTags = selectedTags ? [...selectedTags] : [];
    const existingTag = newSelectedTags.findIndex((tag) => tag.value === value);
    if (existingTag > -1) {
      newSelectedTags.splice(existingTag, 1);
      onChangeTags && onChangeTags(newSelectedTags);
      return setSelectedTags(newSelectedTags);
    }
    // only allow one to be selected
    if (!multiple) {
      newSelectedTags = [{ value: value, label: label }];
    } else {
      newSelectedTags.push({ value: value, label: label });
    }
    onChangeTags && onChangeTags(newSelectedTags);
    setSelectedTags(newSelectedTags);
  };

  const doDeleteTagConfirm = (value) => {
    confirmDialog({
      message: "Are you sure you want to delete this tag?",
      accept: () => doDeleteTag(value),
      className: "confirm-dialog",
      acceptClassName: "button",
      rejectClassName: "button button__invert",
    });
  };

  const doDeleteTag = (value) => {
    if (!tags) return;
    let newTags = [...tags];
    let newSelectedTags = selectedTags ? [...selectedTags] : [];
    const selectedTag = newTags.findIndex((tag) => tag.value === value);
    const existingTag = newSelectedTags.findIndex((tag) => tag.value === value);
    if (selectedTag > -1) {
      newTags.splice(selectedTag, 1);
      setTags(newTags);
      if (existingTag > -1) {
        newSelectedTags.splice(existingTag, 1);
        onChangeTags && onChangeTags(newSelectedTags);
        setSelectedTags(newSelectedTags);
      }
      onTagDelete && onTagDelete(value);
    }
  };

  const doCreateTag = (e) => {
    if (e.key === "Enter") {
      if (tagInput?.current?.value) {
        setBusy(true);
        onTagCreate(tagInput?.current?.value);
        if (tagInput.current) tagInput.current.value = "Adding tag...";
      }
      e.preventDefault();
    }
  };

  return (
    <div
      className={
        "tags" +
        (className ? ` ${className}` : "") +
        (showingTagSelection ? " tags__active" : "") +
        (!canChange ? " tags__passive" : "")
      }
      ref={tagTrigger}
      tabIndex="0"
      onClick={(e) => {
        if (!canChange) return;
        if (e.target?.className === "tags--tag-input") {
          return e.preventDefault();
        }
        setShowingTagSelection(!showingTagSelection);
      }}
    >
      {selectedTags &&
        selectedTags.map((tag, key) => (
          <div className="tags--tag" key={key}>
            <Tag size={16} strokeWidth={1} /> {tag.label}
          </div>
        ))}
      {(!selectedTags || selectedTags.length === 0) && (
        <div className="tags--tag">
          <Tag size={16} strokeWidth={1} />
        </div>
      )}
      {showingTagSelection && (
        <div
          className={
            "tags--tag-selection" + (busy ? " tags--tag-selection__busy" : "")
          }
          tabIndex="0"
          autoFocus
          ref={tagSelect}
          onBlur={(e) => {
            if (
              tagTrigger?.current &&
              e.relatedTarget !== tagTrigger.current &&
              !e.relatedTarget?.classList?.contains("tags--tag") &&
              e.relatedTarget?.className !== "tags--tag-input" &&
              e.relatedTarget?.className !== "tags--tag-delete" &&
              !e.relatedTarget?.closest(`.tags--tag`)
            ) {
              setShowingTagSelection(false);
            }
          }}
        >
          {tags?.map((tag, key) => (
            <div
              key={key}
              className={
                "tags--tag" +
                (selectedIds.includes(tag.value) ? " tags--tag__selected" : "")
              }
            >
              <button
                type="button"
                className="tags--tag-button"
                key={key}
                aria-label={`Select tag: '${tag.label}'`}
                onClick={() => {
                  doToggleTag(tag.label, tag.value);
                }}
              >
                {tag.label}
              </button>
              {onTagDelete && (
                <button
                  type="button"
                  className="tags--tag-delete"
                  onClick={() => doDeleteTagConfirm(tag.value)}
                >
                  <Cross size={12} strokeWidth={1} />
                </button>
              )}
            </div>
          ))}
          {onTagCreate && (
            <>
              <input
                type="text"
                placeholder="Create tag"
                ref={tagInput}
                className="tags--tag-input"
                disabled={busy}
                onKeyDown={doCreateTag}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Tags;
