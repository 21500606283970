import React, { useState } from "react";
import Error from "./error";
import { request } from "../../utilities/fetch";
import useStore from "../hooks/useStore";

import "../../../scss/components/resetPasswordForm.scss";

const ResetPasswordForm = ({ onClose }) => {
  const clientRef = useStore((state) => state.clientRef);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [fetching, setFetching] = useState(false);
  const [success, setSuccess] = useState(false);

  const doChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const doResetPassword = async () => {
    if (!email) return setError("Please enter your email address.");
    setFetching(true);
    try {
      await request({
        url: `/scripts/reset-password/${email}?account=${clientRef}`,
      });
      // If the request is successful, show a success message
      setSuccess(true);
      setError("");
      setFetching(false);
      if (onClose) {
        setTimeout(() => {
          onClose();
        }, 3200);
      }
    } catch (err) {
      console.log(err);
      setFetching(false);
      if (err?.status === 400 || err?.status === 404) {
        // Display the specific error message from the API response
        setError(err?.bodyError);
      } else {
        // Display a generic error message for other errors
        setError("An error occurred while processing your request.");
      }
    }
  };

  const buttonClass = fetching ? "button disabled" : "button";

  return (
    <>
      <h2>Reset your password</h2>
      <form
        className="reset-password-form"
        onSubmit={(e) => {
          e.preventDefault();
          doResetPassword();
        }}
      >
        <input
          type="email"
          placeholder="Enter your email address"
          value={email}
          autoFocus
          onChange={doChangeEmail}
        />
        <button
          className={buttonClass}
          type="button"
          disabled={fetching}
          onClick={doResetPassword}
        >
          {fetching ? "Resetting..." : "Reset password"}
        </button>
      </form>
      <Error
        message={error || (success && "Password reset email sent.")}
        type={success ? "success" : null}
        onMessageShown={() => {
          setError("");
          setSuccess("");
        }}
      />
    </>
  );
};

export default ResetPasswordForm;
