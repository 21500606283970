import("../../../scss/components/closeButton.scss");
import SVG from "./svg";

const CloseButton = ({ className, onClick }) => {
  const buttonClass = "close-button" + (className ? " " + className : "");
  return (
    <button
      className={buttonClass}
      aria-label="Close"
      type="button"
      onClick={onClick}
    >
      <SVG src="close" className="icon" label="Close" />
    </button>
  );
};

export default CloseButton;
