import utils from "../../utilities";
import Notice from "./notice";

const RevisionHistory = ({ data }) => {
  if (!data || data?.length === 0)
    return (
      <>
        <Notice message="No changes have been recorded for this section" />
      </>
    );
  return (
    <>
      <div className="notice notice__invert flex flex__mb">
        Key:
        <span className="diff--added">Added</span>
        <span className="diff--removed">Removed</span>
      </div>
      <div className="revisions-history">
        {data?.map((row, i) => (
          <div key={row.id}>
            <h2>{utils.getFormattedDateTime(row.dateTime)}</h2>
            <div
              className="data-table--value"
              dangerouslySetInnerHTML={{
                __html: row.changes,
              }}
            ></div>
            {i !== data.length - 1 && <hr className="data-table--divide" />}
          </div>
        ))}
      </div>
    </>
  );
};

export default RevisionHistory;
