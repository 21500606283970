import {
  useQuery,
  useQueryClient,
  useMutation,
  useIsMutating,
} from "react-query";
import { request } from "../../../utilities/fetch.js";

export const useFetchAbsences = (options = {}) => {
  const { account = null, start = null, end = null, callback } = options;

  const isMutating = useIsMutating({ mutationKey: "absence" });
  return useQuery(
    ["absences", account, start, end],
    () =>
      request({
        url: "/holidays",
        params: { start: start, end: end, account: account },
      }),
    {
      refetchInterval: false,
      staleTime: 300000,
      select: (data) => data?.data?.items,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};
export const useFetchAbsence = (options = {}) => {
  const {
    account = null,
    absenceId = null,
    callback,
    callbackSettled,
  } = options;
  const isMutating = useIsMutating({ mutationKey: "absence" });
  return useQuery(
    ["absence", account, absenceId],
    () => {
      if (!absenceId) return Promise.resolve({});
      return request({
        url: `/holidays/${absenceId}`,
        params: { account: account },
      });
    },
    {
      refetchInterval: false,
      staleTime: 300000,
      select: (data) => data?.data,
      onSuccess: callback,
      onSettled: callbackSettled,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useFetchUserAbsences = (options = {}) => {
  const { account = null, userId = null, year = null, callback } = options;
  const isMutating = useIsMutating({ mutationKey: "absence" });
  return useQuery(
    ["userAbsence", userId, account],
    () => {
      if (!userId) return Promise.resolve({});
      return request({
        url: `/users/absences/${userId}`,
        params: { account: account, year: year },
      });
    },
    {
      refetchInterval: false,
      select: (data) => data?.data,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useUpdateAbsence = (options = {}) => {
  const {
    account = null,
    start = null,
    end = null,
    callback,
    callbackSettled,
  } = options;
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const absenceId = data?.holidayId;
      if (!absenceId) return Promise.resolve({});
      if (absenceId) {
        const sendData = {
          ...data,
        };
        delete sendData.holidayId;
        return request({
          url: `/holidays/${absenceId}`,
          params: { account: account },
          method: "patch",
          data: sendData,
          json: true,
        });
      }
    },
    {
      mutationKey: "absence",
      onMutate: async (data) => {
        await queryClient.cancelQueries(["absences", account, start, end]);
        // optimistic update data
        const absenceId = data?.holidayId;
        const newData = {
          ...data,
        };
        if (absenceId && data) {
          // update absences collection
          const currentAbsences = queryClient.getQueryData([
            "absences",
            account,
            start,
            end,
          ]);
          const updatedAbsences = [...currentAbsences.data.items];
          const replaceIndex = updatedAbsences.findIndex((absence) => {
            return !!(absence.holidayId === absenceId);
          });
          if (replaceIndex >= 0) {
            updatedAbsences[replaceIndex] = {
              ...updatedAbsences[replaceIndex],
              ...newData,
            };
          }
          await queryClient.setQueryData(["absences", account, start, end], {
            data: { items: [...updatedAbsences] },
          });
        }
        if (callback && typeof callback != "undefined") callback(data);
        // update absence
        const currentAbsence = queryClient.getQueryData([
          "absence",
          account,
          absenceId,
        ])?.data;
        await queryClient.setQueryData(["absence", account, absenceId], {
          data: { ...currentAbsence, ...newData },
        });
        queryClient.invalidateQueries(["absences", account, start, end]);
        queryClient.invalidateQueries(["absence", account, absenceId]);
      },
      onSettled: (data) => {
        const absenceId = data?.holidayId;
        queryClient.invalidateQueries(["absences", account, start, end]);
        queryClient.invalidateQueries(["absence", account, absenceId]);
        queryClient.invalidateQueries(["notifications"]);
        callbackSettled && callbackSettled(data?.data);
      },
    }
  );
};

export const useCreateAbsence = (options = {}) => {
  const { account = null, start = null, end = null, callback } = options;
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const sendData = {
        ...data,
      };
      return request({
        url: `/holidays`,
        method: "post",
        params: { account: account },
        data: sendData,
      });
    },
    {
      mutationKey: "absence",
      onMutate: async (data) => {
        await queryClient.cancelQueries(["absences", account, start, end]);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["absences", account, start, end]);
        queryClient.invalidateQueries(["notifications"]);
      },
      onSuccess: callback,
    }
  );
};

export const useDeleteAbsence = ({
  account,
  start = null,
  end = null,
  callback,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => {
      return request({
        url: `/holidays/${id}`,
        params: { account: account },
        method: "delete",
      });
    },
    {
      mutationKey: ["absence"],
      onMutate: async (id) => {
        await queryClient.cancelQueries(["absences", account, start, end]);
        const currentAbsenceData = queryClient.getQueryData([
          "absences",
          account,
          start,
          end,
        ]);
        // optimistic update data
        if (currentAbsenceData?.data?.items?.length > 0) {
          const updated = [...currentAbsenceData.data.items];
          const deleteIndex = updated.findIndex(
            (absence) => absence?.holidayId === id
          );
          updated.splice(deleteIndex, 1);
          await queryClient.setQueryData(["absences", account, start, end], {
            data: { items: [...updated] },
          });
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["absences", account, start, end]);
      },
      onSuccess: callback,
    }
  );
};

export default useFetchAbsences;
