import SVG from "./svg";
import * as akar from "akar-icons";

const Icon = ({
  className = "icon",
  type = "custom",
  src,
  iconStyle,
  alt,
  size,
  strokeWidth,
}) => {
  let icon = null;

  const fillStroke = type === "custom" ? true : false;

  const baseClassName =
    fillStroke === true ? "item-icon item-icon__fill-stroke" : "item-icon";

  switch (type) {
    case "custom":
    case "customMenu":
      icon = (
        <SVG
          src={src}
          style={iconStyle}
          alt={alt}
          strokeWidth={strokeWidth}
          className={baseClassName + (className ? ` ${className}` : "")}
        />
      );
      break;
    case "akar":
      component = (
        <AkarComponent {...{ src, size, alt, iconStyle, strokeWidth }} />
      );
      if (className && className !== "icon") {
        icon = (
          <div className={baseClassName + (className ? ` ${className}` : "")}>
            {component}
          </div>
        );
      } else icon = component;
  }

  return <>{icon}</>;
};

const AkarComponent = ({ src, size, alt, iconStyle, strokeWidth }) => {
  const Akar = akar[src];
  if (!Akar) return null;
  return (
    <Akar size={size} alt={alt} strokeWidth={strokeWidth} style={iconStyle} />
  );
};

export default Icon;
