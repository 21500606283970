import Icon from "./icon";
import { useRef, useEffect } from "react";
import useStore from "../../components/hooks/useStore";

let customIcons = ["menu-hr", "menu-hs", "menu-td", "wellbeing", "triangles"];

customIcons = customIcons.map((icon) => ({
  icon,
  type: "custom",
}));

let akarIcons = [
  "Air",
  "AirplayAudio",
  "AirplayVideo",
  "Airpods",
  "Alarm",
  "AlignBottom",
  "AlignHorizontalCenter",
  "AlignLeft",
  "AlignRight",
  "AlignToBottom",
  "AlignToMiddle",
  "AlignToTop",
  "AlignTop",
  "AlignVerticalCenter",
  "AndroidFill",
  "AngularFill",
  "ArrowBack",
  "ArrowBackThick",
  "ArrowBackThickFill",
  "ArrowClockwise",
  "ArrowCounterClockwise",
  "ArrowCycle",
  "ArrowDown",
  "ArrowDownLeft",
  "ArrowDownRight",
  "ArrowDownThick",
  "ArrowForward",
  "ArrowForwardThick",
  "ArrowForwardThickFill",
  "ArrowLeft",
  "ArrowLeftThick",
  "ArrowRepeat",
  "ArrowRight",
  "ArrowRightLeft",
  "ArrowRightThick",
  "ArrowShuffle",
  "ArrowUp",
  "ArrowUpDown",
  "ArrowUpLeft",
  "ArrowUpRight",
  "ArrowUpThick",
  "Ascending",
  "Attach",
  "Backspace",
  "BackspaceFill",
  "Basket",
  "BatteryCharging",
  "BatteryEmpty",
  "BatteryFull",
  "BatteryLow",
  "BatteryMedium",
  "BehanceFill",
  "Bell",
  "Bicycle",
  "BitcoinFill",
  "Block",
  "Bluetooth",
  "Boat",
  "BookClose",
  "BookOpen",
  "BootstrapFill",
  "Box",
  "Briefcase",
  "Bug",
  "Cake",
  "Calculator",
  "Calendar",
  "Camera",
  "Cart",
  "ChatAdd",
  "ChatApprove",
  "ChatBubble",
  "ChatDots",
  "ChatEdit",
  "ChatError",
  "ChatQuestion",
  "ChatRemove",
  "Check",
  "CheckBox",
  "CheckBoxFill",
  "CheckIn",
  "Chess",
  "ChevronDown",
  "ChevronHorizontal",
  "ChevronLeft",
  "ChevronRight",
  "ChevronUp",
  "ChevronVertical",
  "Circle",
  "CircleAlert",
  "CircleAlertFill",
  "CircleCheck",
  "CircleCheckFill",
  "CircleChevronDown",
  "CircleChevronDownFill",
  "CircleChevronLeft",
  "CircleChevronLeftFill",
  "CircleChevronRight",
  "CircleChevronRightFill",
  "CircleChevronUp",
  "CircleChevronUpFill",
  "CircleFill",
  "CircleMinus",
  "CircleMinusFill",
  "CirclePlus",
  "CirclePlusFill",
  "CircleTriangleDown",
  "CircleTriangleDownFill",
  "CircleTriangleLeft",
  "CircleTriangleLeftFill",
  "CircleTriangleRight",
  "CircleTriangleRightFill",
  "CircleTriangleUp",
  "CircleTriangleUpFill",
  "CircleX",
  "CircleXFill",
  "Clipboard",
  "Clock",
  "Cloud",
  "CloudDownload",
  "CloudUpload",
  "CodepenFill",
  "Coffee",
  "Coin",
  "Command",
  "Computing",
  "Copy",
  "CreditCard",
  "Cross",
  "Crown",
  "CssFill",
  "Cursor",
  "Cut",
  "Dental",
  "Descending",
  "DesktopDevice",
  "Devices",
  "Diamond",
  "DiscordFill",
  "DjangoFill",
  "Door",
  "DotGridFill",
  "DoubleCheck",
  "DoubleSword",
  "Download",
  "Draft",
  "DragHorizontalFill",
  "DragVerticalFill",
  "DribbbleFill",
  "DropboxFill",
  "Edit",
  "Enlarge",
  "Envelope",
  "EyeClosed",
  "EyeOpen",
  "EyeSlashed",
  "FaceHappy",
  "FaceNeutral",
  "FaceSad",
  "FaceVeryHappy",
  "FaceVerySad",
  "FaceWink",
  "FacebookFill",
  "FigmaFill",
  "File",
  "Filter",
  "Fire",
  "Flag",
  "Flashlight",
  "Folder",
  "FolderAdd",
  "Frame",
  "FullScreen",
  "GameController",
  "GatsbyFill",
  "Gear",
  "Gift",
  "GithubFill",
  "Glasses",
  "Globe",
  "GoogleContainedFill",
  "GoogleFill",
  "GraphqlFill",
  "Grid",
  "Hand",
  "Hashtag",
  "Headphone",
  "Health",
  "Heart",
  "Height",
  "Heptagon",
  "HeptagonFill",
  "Hexagon",
  "HexagonFill",
  "Home",
  "HtmlFill",
  "Image",
  "Inbox",
  "Infinite",
  "Info",
  "InfoFill",
  "InstagramFill",
  "Jar",
  "JavascriptFill",
  "JqueryFill",
  "Key",
  "KeyCap",
  "LaptopDevice",
  "Leaf",
  "Lifesaver",
  "LightBulb",
  "LinkChain",
  "LinkOff",
  "LinkOn",
  "LinkOut",
  "LinkedinBoxFill",
  "LinkedinFill",
  "Location",
  "LockOff",
  "LockOn",
  "Map",
  "MediumFill",
  "Mention",
  "Microphone",
  "Miniplayer",
  "Minus",
  "MobileDevice",
  "Money",
  "Moon",
  "MoonFill",
  "MoreHorizontalFill",
  "MoreVerticalFill",
  "Music",
  "MusicAlbum",
  "MusicAlbumFill",
  "MusicNote",
  "Network",
  "Newspaper",
  "NextjsFill",
  "NodeFill",
  "NormalScreen",
  "NpmFill",
  "Octagon",
  "OctagonFill",
  "OctocatFill",
  "OpenEnvelope",
  "Oval",
  "Paper",
  "Parallelogram",
  "Pause",
  "Pencil",
  "Pentagon",
  "PentagonFill",
  "PeopleGroup",
  "PeopleMultiple",
  "Percentage",
  "Person",
  "PersonAdd",
  "PersonCheck",
  "PersonCross",
  "Phone",
  "PhpFill",
  "Pin",
  "PinterestFill",
  "Plane",
  "PlaneFill",
  "Planet",
  "Plant",
  "Play",
  "Plus",
  "PointerDownFill",
  "PointerHand",
  "PointerLeftFill",
  "PointerRightFill",
  "PointerUpFill",
  "PointingUp",
  "PostgresqlFill",
  "PythonFill",
  "Question",
  "QuestionFill",
  "Radish",
  "ReactFill",
  "RedditFill",
  "Reduce",
  "ReduxFill",
  "Ribbon",
  "RockOn",
  "Rss",
  "SassFill",
  "Schedule",
  "Scissor",
  "Search",
  "Send",
  "SettingsHorizontal",
  "SettingsVertical",
  "ShareBox",
  "Shield",
  "ShippingBoxV1",
  "ShippingBoxV2",
  "ShoppingBag",
  "SidebarLeft",
  "SidebarRight",
  "SignOut",
  "SlackFill",
  "Slice",
  "SnapchatFill",
  "SoundDown",
  "SoundOff",
  "SoundOn",
  "SoundUp",
  "SoundcloudFill",
  "Sparkles",
  "SpotifyFill",
  "Square",
  "SquareFill",
  "StackOverflowFill",
  "Star",
  "StatisticDown",
  "StatisticUp",
  "Stop",
  "StopFill",
  "Sun",
  "SunFill",
  "Sword",
  "TabletDevice",
  "Tag",
  "TelegramFill",
  "Telescope",
  "Tetragon",
  "TetragonFill",
  "TextAlignCenter",
  "TextAlignJustified",
  "TextAlignLeft",
  "TextAlignRight",
  "ThreeLineHorizontal",
  "ThreeLineVertical",
  "ThumbsDown",
  "ThumbsUp",
  "Thunder",
  "Ticket",
  "ToggleOff",
  "ToggleOffFill",
  "ToggleOn",
  "ToggleOnFill",
  "TogoCup",
  "TrashBin",
  "TrashCan",
  "Triangle",
  "TriangleAlert",
  "TriangleAlertFill",
  "TriangleDown",
  "TriangleFill",
  "TriangleLeft",
  "TriangleRight",
  "TriangleUp",
  "Trophy",
  "Truck",
  "TumblrFill",
  "TwitchFill",
  "TwitterFill",
  "TwoLineHorizontal",
  "TwoLineVertical",
  "Umbrella",
  "Utensils",
  "VapeKit",
  "VercelFill",
  "VictoryHand",
  "Video",
  "VimeoFill",
  "VkFill",
  "VrAr",
  "VscodeFill",
  "VueFill",
  "Wallet",
  "WatchDevice",
  "Water",
  "WhatsappFill",
  "Width",
  "Wifi",
  "WineGlass",
  "YarnFill",
  "YoutubeFill",
  "ZoomFill",
  "ZoomIn",
  "ZoomOut",
];

akarIcons = akarIcons.map((icon) => ({
  icon,
  type: "akar",
}));

const IconSelection = ({ onSelect, onHide }) => {
  const isAdmin = useStore((state) => state.getIsAdmin());
  const icons = [...customIcons, ...akarIcons];
  if (!isAdmin) icons.splice(4, 1);
  const iconInput = useRef(null);

  useEffect(() => {
    if (iconInput.current) iconInput.current?.focus();
  }, [iconInput]);
  return (
    <div
      className="icon-picker"
      tabIndex="0"
      autoFocus
      ref={iconInput}
      onBlur={onHide}
    >
      <div className="icon-picker--grid">
        {icons.map((icon, key) => (
          <div
            key={key}
            className="icon-picker--grid-item"
            onClick={(e) => {
              e.preventDefault();
              onSelect(e, {
                type: icon.type,
                icon: icon.icon,
              });
            }}
          >
            <Icon
              type={icon.type}
              src={icon.icon}
              iconStyle={{ width: "32px" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default IconSelection;
