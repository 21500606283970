import { useState, useEffect } from "react";

const Radio = ({
  id,
  name,
  value,
  required,
  label,
  disabled,
  checked: initChecked,
  size: initSize,
  register,
  validation,
  setValue,
  onClick,
  onChange,
}) => {
  const size = initSize ? parseInt(initSize) : 28;
  const [checked, setChecked] = useState(initChecked);
  useEffect(() => {
    const isChecked = checked ? true : false;
    if (isChecked) {
      setValue && setValue(`${name}`, value);
    }
  }, [name, value]);
  const doChecked = (checked) => {
    const isChecked = checked ? true : false;
    setChecked(isChecked);
    onClick && onClick(isChecked);
  };
  return (
    <>
      <label
        htmlFor={id}
        className="button-link radio"
        data-disabled={disabled}
      >
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          defaultChecked={checked}
          onInput={onChange}
          onChange={() => {
            doChecked(!checked);
          }}
          {...register(name, {
            required: {
              value: required ? true : false,
              message: `Please choose an option for ${label?.toLowerCase()}`,
            },
            ...validation("text"),
          })}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <circle
            cx="12"
            cy="12"
            r="5"
            className="radio-button"
            fill="currentColor"
          ></circle>
        </svg>
      </label>
    </>
  );
};

export default Radio;
