// Import React and the skeleton SCSS file
import React from "react";
import("../../../scss/components/skeleton.scss");

const Skeleton = ({
  lines, // The number of lines to render
  image, // Whether to render an image or not
  type, // The type of skeleton to render
  className, // Any additional classes to apply to the skeleton image
  contain = false, // Whether the skeleton should be contained in a div or not
  height = null, // The height of the image, if applicable
  fixedWidth = false, // Whether to fix the width of the skeleton rows
}) => {
  // Determine the class name for the skeleton image
  const imageClassName = className
    ? `skeleton-image ${className}`
    : "skeleton-image";
  // Determine the number of rows to render
  const rows = lines || 1;

  // Render a calendar skeleton if the type is "calendar"
  if (type === "calendar") {
    let cells = [];
    for (let r = 0; r < 42; r++) {
      cells.push("");
    }
    return (
      <div className="skeleton-calendar">
        {cells.map((cell, index) => (
          <div key={index}></div>
        ))}
      </div>
    );
  }

  // Render a grid skeleton if the type is "grid"
  if (type === "grid") {
    let cells = [];
    for (let r = 0; r < rows; r++) {
      cells.push("");
    }
    return (
      <div className="skeleton-grid">
        {cells.map((cell, index) => (
          <React.Fragment key={index}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </React.Fragment>
        ))}
      </div>
    );
  }

  if (type === "cover") {
    return (
      <div className="skeleton-cover">
        <div className="skeleton-line"></div>
      </div>
    );
  }

  // Render a messages skeleton if the type is "messages"
  if (type === "messages") {
    let cells = [];
    for (let r = 0; r < 5; r++) {
      cells.push("");
    }
    return (
      <div className="skeleton-messages">
        {cells.map((cell, index) => (
          <div className="skeleton-line" key={index}></div>
        ))}
      </div>
    );
  }

  if (type === "files-thumbs") {
    let cells = [];
    for (let t = 0; t < 10; t++) {
      cells.push("");
    }
    return (
      <div className="skeleton-files-thumbs">
        {cells.map((cell, index) => (
          <div key={index}></div>
        ))}
      </div>
    );
  }

  if (type === "form-cols") {
    return (
      <div className="skeleton-form-cols">
        <div></div>
        <div></div>
      </div>
    );
  }

  // Render an image skeleton if image is true
  if (image) {
    return (
      <img
        className={imageClassName}
        style={height && { height: height }}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      />
    );
  }

  // Render a skeleton without containing if contain is false
  if (!contain) {
    return <SkeletonRows rows={rows} fixedWidth={fixedWidth} />;
  }

  // Render a contained skeleton
  return (
    <div className="skeleton">
      <SkeletonRows rows={rows} fixedWidth={fixedWidth} />
    </div>
  );
};

const SkeletonRows = ({ rows, fixedWidth }) => {
  const output = [];
  const min = 95; // minimum width for skeleton line
  const max = 100; // maximum width for skeleton line
  for (let row = 1; row <= rows; row++) {
    // generate random width for each skeleton line
    const randomWidthLarge = Math.floor(Math.random() * (max - min + 1) + min);
    const randomWidthSmall = Math.floor(Math.random() * (50 - 20 + 1) + 20);
    const thisWidth =
      rows === 1 // if there's only one row, set width to 100%
        ? 100
        : row % 10 === 0 // every 10th row, set a small width
        ? randomWidthSmall
        : randomWidthLarge; // otherwise, set a large random width
    const thisStyle = fixedWidth
      ? { width: "100%" } // if fixedWidth prop is true, set width to 100%
      : {
          width: `calc(${thisWidth}% - 2rem)`, // otherwise, set a dynamic width using calc() CSS function
        };
    // set appropriate className based on row number
    const className =
      row % 10 === 0
        ? "skeleton-line skeleton-line__end-paragraph"
        : "skeleton-line";
    output.push(<div className={className} style={thisStyle} key={row}></div>);
  }
  return output; // return an array of divs to render
};

export default Skeleton;
