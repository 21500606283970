const RadioInput = ({
  id,
  name,
  value,
  required,
  label,
  disabled,
  currentValue,
  size: initSize,
  register,
  validation,
  setValue,
  onChange,
}) => {
  const size = initSize ? parseInt(initSize) : 28;
  const isChecked = !!(currentValue && currentValue === value);

  const doChecked = (e) => {
    setValue && setValue(name, value);
    onChange && onChange(e);
  };

  // ensure the hidden radio element is checked if it's supposed to be
  setTimeout(() => {
    const el = document.getElementById(id);
    if (el) el.checked = isChecked ? true : false;
  }, 1);

  return (
    <>
      <label
        htmlFor={id}
        className="button-link radio"
        data-disabled={disabled}
      >
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={isChecked}
          onInput={doChecked}
          onClick={doChecked}
          onChange={doChecked}
          {...register(name, {
            required: {
              value: required ? true : false,
              message: `Please choose an option for ${label?.toLowerCase()}`,
            },
            ...validation("text"),
          })}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <circle
            cx="12"
            cy="12"
            r="5"
            className="radio-button"
            fill="currentColor"
          ></circle>
        </svg>
      </label>
    </>
  );
};

export default RadioInput;
