import { useState, useEffect } from "react";

const Error = ({ message, type, onMessageShown }) => {
  const [errorMessage, setErrorMessage] = useState(message);
  useEffect(() => {
    setErrorMessage(message);
    if (message) {
      setTimeout(() => {
        if (onMessageShown) {
          return onMessageShown();
        }
        setErrorMessage(null);
      }, 3000);
    }
  }, [message]);

  let className = errorMessage ? "error-message active" : "error-message";
  className += type ? ` ${type}` : "";
  return (
    <div className={className} id="error-message">
      {message}
    </div>
  );
};

export default Error;
