import { Suspense, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import toast from "react-hot-toast";
import Throbber from "./components/common/throbber";
import DevInfo from "./components/common/devInfo";
import {
  QueryClientProvider,
  QueryClient,
  QueryCache,
  MutationCache,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import App from "./app";
import DevInfo from "./components/common/devInfo";

const handleServerIsDown = (serverIsDown) => {
  const serverDownElement = document.getElementById("server-down");
  if (serverIsDown && !serverDownElement) {
    const serverDown = document.createElement("div");
    serverDown.id = "server-down";
    serverDown.innerHTML = `
        <div class="server-down">
          <p>YourHR.space is currently down for maintenance.</p>
          <p>Please check back soon.</p>
        </div>
      `;
    return document.body.appendChild(serverDown);
  }
};

// get subdomain
const subdomain = window.location.hostname.split(".")[0];
if (subdomain === "app") {
  document.querySelector("body").classList.add("app");
  // remove viewport meta tag
  const viewport = document.querySelector('meta[name="viewport"]');
  if (viewport) viewport.remove();
  // add meta tags to prevent user scaling / pinch zoom on app subdomain
  const meta = document.createElement("meta");
  meta.name = "viewport";
  meta.content =
    "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
  document.head.appendChild(meta);
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error.toasterMessage) {
        toast.error(`${error.toasterMessage}`, { id: error.toasterMessage });
      }
      if (error.status === 503) return handleServerIsDown(true);
      handleServerIsDown(false);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (error.toasterMessage) {
        toast.error(`${error.toasterMessage}`, { id: error.toasterMessage });
      }
      if (error.status === 503) return handleServerIsDown(true);
      handleServerIsDown(false);
    },
  }),
});

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register(new URL("serviceWorker.js", import.meta.url))
//     .then((registration) => {
//       console.log("SW Registered!");
//     })
//     .catch((error) => {
//       console.log("SW Registration Failed");
//     });
// } else {
//   // console.log("Not supported");
// }
ReactDOM.render(
  <Suspense
    fallback={
      <div className="throbber-container">
        <Throbber />
      </div>
    }
  >
    <QueryClientProvider client={queryClient}>
      <App />
      {process.env.NODE_ENV === "development" && (
        <>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <DevInfo />
        </>
      )}
    </QueryClientProvider>
  </Suspense>,
  document.getElementById("root")
);
