import { useState, useRef, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import utils from "../../utilities";
import Throbber from "./throbber";
import Skeleton from "./skeleton";
import EditableText from "./editableText";
import Tags from "./tags";
import SVG from "./svg";
import { TrashCan, CloudUpload, Download } from "akar-icons";
import { useFetchImage } from "../hooks/fetch/useFetchResources";

const ImageBrowse = ({
  type = "images",
  skeleton = false,
  data,
  selectedImageSlug,
  account,
  disabled = false,
  editIcons = false,
  allowDrop = false,
  filterTags = null,
  allTags = [],
  showFileUpload = false,
  busy = false,
  onDelete,
  onClick,
  onUpload,
  onUploadBrowse,
  onRename,
  onChangeTags,
  onTagCreate,
  onTagDelete,
  onDownloadFile,
}) => {
  const [previewImageSlug, setPreviewImageSlug] = useState();
  const { data: previewImage, isImagePending } = useFetchImage({
    account: account,
    imageSlug: previewImageSlug,
  });

  const [images, setImages] = useState(data);
  const [uploadReplaceId, setUploadReplaceId] = useState();
  const [browseFiles, setBrowseFiles] = useState(false);

  useEffect(() => {
    setBrowseFiles(!!showFileUpload);
    setUploadReplaceId(
      showFileUpload && Array.isArray(showFileUpload) && showFileUpload[0]
        ? showFileUpload[0]
        : null
    );
  }, [showFileUpload]);

  useEffect(() => {
    if (data) {
      setImages(data);
    }
  }, [data]);

  const imageAccept = {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/webp": [".webp"],
    "image/svg+xml": [".svg"],
    "image/avif": [".avif"],
  };

  const fileAccept = {
    ...imageAccept,
    "video/mp4": [".mp4"],
    "video/ogg": [".ogv"],
    "video/webm": [".webm"],
    "video/3gpp": [".3gp", ".3gpp"],
    "audio/mpeg3": [".mp3"],
    "audio/aac": [".aac"],
    "audio/ogg": [".ogg"],
    "application/vnd.ms-excel": [".xls", ".xlsx"],
    "application/msword": [".doc", ".docx"],
    "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
    "application/pdf": [".pdf"],
    "application/rtf": [".rtf"],
    "text/csv": [".csv"],
    "text/plain": [".txt"],
    "application/zip": [".zip"],
    "application/gzip": [".gzip"],
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    accept: type === "images" ? imageAccept : fileAccept,
    onDragEnter: () => {
      // if this is called, user is using drag/drop, so we need to clear replacement id
      setUploadReplaceId(null);
    },
    onDrop: (files) => {
      if (onUpload && files && files.length > 0) {
        onUpload(files, uploadReplaceId);
      }
    },
    multiple: true,
  });

  const doDelete = (e, id) => {
    e.preventDefault();
    if (onDelete) onDelete(id);
  };

  const doRename = (id, value) => {
    if (onRename) {
      onRename(id, value);
    }
  };

  const doUpload = (e, id) => {
    e.preventDefault();
    if (onUploadBrowse) onUploadBrowse(id);
  };

  const doPreviewImage = (image) => {
    if (!onClick) {
      return setPreviewImageSlug(image?.slug);
    }
    return onClick(image);
  };

  const doChangeTags = (id, tags) => {
    const folder =
      tags && tags.length > 0
        ? { folderId: tags[0].value, description: tags[0].label }
        : {};
    const imageIndex = images.findIndex((image) =>
      image.imageId === id ? true : false
    );
    if (imageIndex > -1) {
      const newImages = [...images];
      newImages[imageIndex].folder = folder;
      setImages(newImages);
    }
    onChangeTags && onChangeTags(id, tags);
  };

  useEffect(() => {
    if (browseFiles) {
      hiddenFileInput?.current.click();
      setBrowseFiles(false);
    }
  }, [browseFiles]);

  const hiddenFileInput = useRef(null);

  const getStyle = (image) => {
    if (
      filterTags &&
      filterTags.length > 0 &&
      (!image.folder?.folderId || !filterTags.includes(image.folder?.folderId))
    ) {
      return { display: "none" };
    }
    return {};
  };
  const classNameMod = useMemo(() => {
    if (isFocused) {
      focused = true;
      return "file-drop-target__focused";
    }
    if (isDragAccept) {
      accepted = true;
      return "file-drop-target__accepted";
    }
    if (isDragReject) {
      rejected = true;
      return "file-drop-target__rejected";
    }
  }, [isFocused, isDragAccept, isDragReject]);

  let className =
    "image-thumbs-browse file-drop-target" +
    (classNameMod ? ` ${classNameMod}` : "") +
    (disabled ? " disabled" : "");

  if (busy) className += " image-thumbs-browse__busy";
  if (type === "files") className += " image-thumbs-browse__files";

  const getSkeleton = () => {
    let thumbSkeleton = [];
    const numSkeletons = type === "files" ? 50 : 24;
    for (let s = 0; s < numSkeletons; s++) {
      thumbSkeleton.push(
        <div className="image-thumbs-browse--item-container" key={s}>
          <div className="image-thumbs-browse--item">
            <Skeleton
              image={true}
              className={
                type === "files"
                  ? "image-thumbs-browse--item-skeleton-small"
                  : ""
              }
            />
          </div>
        </div>
      );
    }
    return thumbSkeleton;
  };

  const getImageClass = (image) => {
    let className =
      selectedImageSlug && selectedImageSlug === image?.slug ? "selected" : "";

    if (onClick) {
      className += " clickable";
    }
    return className;
  };

  return (
    <>
      <div
        className={className}
        aria-label="Drag and drop images here"
        {...getRootProps()}
      >
        {skeleton && getSkeleton()}

        {!skeleton &&
          images?.map((image, key) => (
            <div
              className="image-thumbs-browse--item-container"
              key={key}
              style={getStyle(image)}
            >
              <div className="image-thumbs-browse--item">
                {type === "images" &&
                  (!image.type || image.type !== "file") && (
                    <>
                      <img
                        src={`${process.env.API_URL}/image/${account}/${image.slug}?size=medium&checksum=${image.hash}`}
                        alt={image.alt}
                        onClick={() => doPreviewImage(image)}
                        className={getImageClass(image)}
                      />
                    </>
                  )}
                {type === "files" && (
                  <>
                    <SVG
                      src={utils.getMimeIcon(image.mime)}
                      label={image.alt}
                    />
                  </>
                )}

                {image.alt && (
                  <div className="image-thumbs-browse--caption">
                    {editIcons && (
                      <EditableText
                        value={image.alt}
                        onSave={(value) => {
                          doRename(image.imageId, value);
                        }}
                      />
                    )}
                    {!editIcons && <>{image.alt}</>}
                  </div>
                )}

                {editIcons && (
                  <div className="image-thumbs-browse--edit-icons edit-icons">
                    <button
                      className="button button__condensed"
                      aria-label="Upload replacement"
                      title="Upload replacement"
                      onClick={(e) => {
                        doUpload(e, image.imageId);
                      }}
                    >
                      <CloudUpload size={22} strokeWidth={1} />
                    </button>
                    {type === "images" && (
                      <a
                        className="button button__condensed"
                        aria-label="Download"
                        title="Download"
                        href={`${process.env.API_URL}/image/${account}/${image.slug}?size=full&download=true`}
                        download
                      >
                        <Download size={22} strokeWidth={1} />
                      </a>
                    )}
                    {type === "files" && (
                      <button
                        className="button button__condensed"
                        aria-label="Download"
                        title="Download"
                        onClick={(e) => {
                          onDownloadFile(e, image.slug, image.fileName);
                        }}
                      >
                        <Download size={22} strokeWidth={1} />
                      </button>
                    )}

                    <button
                      className="button button__condensed"
                      aria-label="Delete"
                      title="Delete"
                      onClick={(e) => {
                        doDelete(e, image.imageId);
                      }}
                    >
                      <TrashCan size={22} strokeWidth={1} />
                    </button>
                  </div>
                )}
              </div>

              <Tags
                className="image-thumbs-browse--tags"
                tags={allTags}
                selectedTags={
                  (image.folder?.folderId && [
                    {
                      value: image.folder?.folderId,
                      label: image.folder?.description,
                    },
                  ]) ||
                  null
                }
                canChange={editIcons}
                onTagCreate={onTagCreate}
                onTagDelete={onTagDelete}
                onChangeTags={(value) => {
                  doChangeTags(image.imageId, value);
                }}
              />
            </div>
          ))}
      </div>
      {previewImageSlug && (
        <div
          className="image-preview-container"
          onClick={() => setPreviewImageSlug()}
        >
          <Throbber />
          {previewImage && (
            <img src={previewImage} alt="Enlarged image - click to close" />
          )}
        </div>
      )}

      <input
        type="file"
        style={{ display: "none" }}
        disabled={disabled}
        multiple
        accept="image/png, image/jpeg, image/webp, image/svg+xml, image/avif"
        {...getInputProps()}
        ref={hiddenFileInput}
      />
    </>
  );
};

export default ImageBrowse;
