import { useState } from "react";
import { useFetchUser } from "../hooks/fetch/useFetchUsers";

const PeriodOverride = ({ id, disabled, value, onChange }) => {
  const { value: periodInit, userId } = value;
  const [periodOverrideValue, setPeriodOverrideValue] = useState(periodInit);
  const { data: user } = useFetchUser({
    userId,
  });

  const doChangePeriodOverride = (e) => {
    setPeriodOverrideValue(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  const daysHours =
    user?.absenceSummary?.absenceUnit === "hours" ? "hours" : "days";
  const label = `Allocate specific number of ${daysHours} to this absence`;

  return (
    <>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        type="number"
        min="0"
        step={0.1}
        disabled={disabled}
        value={periodOverrideValue}
        onChange={doChangePeriodOverride}
      />
    </>
  );
};

export default PeriodOverride;
