import Spinner from "./spinner";
import { useFetchUser } from "../hooks/fetch/useFetchUsers";
import useStore from "../hooks/useStore";

const RemainingLeave = ({ userId }) => {
  const clientRef = useStore((state) => state.clientRef);
  const { data: user, isLoading } = useFetchUser({
    account: clientRef,
    userId,
  });
  if (!userId) return null;
  return (
    <div className="remaining-leave">
      {isLoading ? (
        <Spinner />
      ) : user?.absenceSummary ? (
        <>
          {user?.absenceSummary?.leave?.remaining}{" "}
          {user?.absenceSummary?.absenceUnit} holiday remaining
        </>
      ) : (
        <>Absence details unavailable</>
      )}
    </div>
  );
};

export default RemainingLeave;
