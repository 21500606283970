import { useState, useEffect } from "react";
import RadioInput from "./radioInput";

const RadioFormInputs = ({
  name,
  value,
  options,
  disabled,
  label,
  required,
  setValue,
  register,
  validation,
  onChange,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value, name]);

  const doOnChange = (e, value) => {
    onChange && onChange(e, value);
  };

  return (
    <div className="form-input-flex form-input-flex__tight">
      {(Array.isArray(options) &&
        options.length > 0 &&
        options.map((option, key) => {
          return (
            <div key={key} className="form-input-flex-pair">
              <RadioInput
                id={`${name}-${key}`}
                name={name}
                value={option.value}
                currentValue={currentValue}
                disabled={disabled}
                label={label}
                required={required}
                aria-required={required}
                register={register}
                validation={validation}
                onChange={(e) => {
                  if (option.value) {
                    setCurrentValue(option.value);
                    setValue && setValue(name, option.value);
                    doOnChange(e, option.value);
                  }
                }}
              />
              <label htmlFor={`${name}-${key}`}>{option.label}</label>
            </div>
          );
        })) ||
        null}
    </div>
  );
};

export default RadioFormInputs;
