import React, { useState } from "react";
import SubmitButton from "./submitButton";
import("../../../scss/components/contracts.scss");
import useStore from "../hooks/useStore";

const ContractSign = ({ disabled, onSubmit }) => {
  const currentUser = useStore((state) => state.currentUser);
  const [typedName, setTypedName] = useState("");

  const doSignContract = () => {
    if (typedName.trim().toLowerCase() === currentUser?.name.toLowerCase()) {
      onSubmit && onSubmit();
    } else {
      // Display an error message or handle it as you prefer
      console.error("Please enter your full name.");
    }
  };

  const handleNameChange = (event) => {
    setTypedName(event.target.value);
  };

  const isSubmitDisabled =
    disabled ||
    !typedName.trim() ||
    typedName.trim().toLowerCase() !== currentUser?.name.toLowerCase();

  return (
    <div className="contract-sign-container">
      <label htmlFor="name">
        Please enter your name in full and click the button below to sign this
        document
      </label>
      <input
        id="name"
        type="text"
        placeholder={`Type '${currentUser?.name}'`}
        value={typedName}
        aria-label="Type your name in full"
        onChange={handleNameChange}
      />
      <SubmitButton
        label="Sign document"
        disabled={isSubmitDisabled}
        aria-label="Sign document"
        onSubmit={doSignContract}
      />
    </div>
  );
};

export default ContractSign;
