import { useState, useRef, useEffect } from "react";
import ResetPasswordForm from "./resetPasswordForm";
import MessageUsersForm from "./messageUsersForm";
import MainPageContent from "../mainPageContent";
import CloseButton from "./closeButton";
import PrintButton from "./printButton";
import Spinner from "./spinner";
import "../../../scss/components/modal.scss";

const ModalContent = ({
  articleId,
  userId,
  type,
  content,
  loading,
  messageUsers,
  isWide,
  messageInit,
  onClose,
  onSendMessage = null,
}) => {
  useEffect(() => {
    document.body.classList.add("print-mode");
  }, [content, articleId]);

  const doClose = () => {
    document.body.classList.remove("print-mode");
    onClose && onClose();
  };

  const doPrint = () => {
    const printEl = printComponent.current?.cloneNode(true);
    document.getElementById("print-container")?.replaceChildren(printEl);
    window.print();
  };

  const printComponent = useRef();

  if (!type || (!content && !articleId && !messageUsers && !loading))
    return null;
  if (type === "content" && articleId)
    return (
      <MainPageContent
        articleId={articleId}
        userId={userId}
        isModal={true}
        onClose={doClose}
      />
    );
  console.log(content);

  const articleContent =
    content && typeof content === "object" ? (
      content
    ) : (
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
    );

  const className = isWide
    ? "modal--content modal--content__wide"
    : "modal--content";

  return (
    <div className="modal">
      <div className="modal--overlay" onClick={doClose}></div>

      <CloseButton className="close-button__white" onClick={doClose} />
      <PrintButton className="print-button__white" onClick={doPrint} />

      <div className={className} ref={printComponent}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div>{articleContent}</div>
            {type === "resetPassword" && (
              <ResetPasswordForm onClose={doClose} />
            )}
            {type === "messageUsers" && messageUsers && (
              <MessageUsersForm
                recipients={messageUsers}
                introText={content}
                title={content ? "" : null}
                messageInit={messageInit}
                onClose={doClose}
                onSendMessage={onSendMessage}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ModalContent;
