const KeyValueTable = ({ data }) => {
  // Check if data is an object
  if (typeof data === "object" && data !== null) {
    return (
      <table class="table">
        <tbody>
          {Object.keys(data).map((key) => (
            <tr key={key}>
              <td>{key}</td>
              <td>
                <KeyValueTable data={data[key]} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    // If data is not an object, simply render the value
    return <span>{data}</span>;
  }
};

export default KeyValueTable;
