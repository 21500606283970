import {
  useQuery,
  useQueryClient,
  useMutation,
  useIsMutating,
} from "react-query";
import { request } from "../../../utilities/fetch.js";

export const useFetchResources = ({
  type = "images",
  account = null,
  refetch = true,
  callback,
}) => {
  const isMutating = useIsMutating({ mutationKey: "resources" });
  return useQuery(
    ["resources", type, account],
    () => request({ url: `/resources/${type}`, params: { account: account } }),
    {
      refetchInterval: refetch === false ? false : 30000,
      refetchOnMount: refetch === false ? false : true,
      refetchOnWindowFocus: refetch === false ? false : true,
      select: (data) => {
        if (type === "files") {
          return data?.data?.items?.map((file) => {
            return {
              type: "file",
              alt: file.description,
              slug: file.slug,
              imageId: file.fileId,
              folder: file.folder,
              fileName: file.fileName,
              mime: file.mime,
            };
          });
        }
        return data?.data?.items;
      },
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useUpdateImage = ({
  account = null,
  type = "images",
  callback,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const resourceId = data?.id;
      const sendData = {
        ...data,
      };
      delete sendData.id;
      return request({
        url: `/resources/${type}/${resourceId}`,
        method: "patch",
        params: { account: account },
        data: sendData,
        json: true,
      });
    },
    {
      mutationKey: "resources",
      onMutate: async (data) => {
        await queryClient.cancelQueries(["resources", type, account]);
        const resourceId = data?.id;
        const sendData = { ...data };
        // optimistic update data
        const currentResources = queryClient.getQueryData([
          "resources",
          type,
          account,
        ])?.data?.items;
        const resourceIndex = currentResources.findIndex((resource) => {
          return !!(
            resource.imageId === resourceId || resource.fileId === resourceId
          );
        });
        if (resourceIndex >= 0) {
          const update = [...currentResources];
          update[resourceIndex] = {
            ...update[resourceIndex],
            ...sendData,
          };
          await queryClient.setQueryData(["resources", type, account], {
            data: { ...update },
          });
        }

        queryClient.invalidateQueries(["resources", type, account]);
        if (callback && typeof callback != "undefined") callback(data);
      },
      onSettled: async () => {
        queryClient.invalidateQueries(["resources", type, account]);
      },
    }
  );
};

export const useUploadImage = ({
  account = null,
  type = "images",
  callback,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return request({
        url: `/resources/${type}`,
        method: "post",
        params: { account: account },
        data: data,
        json: true,
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });
    },
    {
      mutationKey: "resources",
      onSuccess: async (data) => {
        await queryClient.cancelQueries(["resources", type, account]);
        // update data
        const currentResources = queryClient.getQueryData([
          "resources",
          type,
          account,
        ])?.data?.items;
        currentResources.push(data);
        await queryClient.setQueryData(["resources", type, account], {
          data: { ...currentResources },
        });
        queryClient.invalidateQueries(["resources", type, account]);
        if (callback && typeof callback != "undefined") callback(data);
      },
      onSettled: async () => {
        queryClient.invalidateQueries(["resources", type, account]);
      },
    }
  );
};

export const useFetchImage = ({
  account = null,
  callback,
  size = "large",
  imageSlug = null,
}) => {
  const isMutating = useIsMutating({ mutationKey: "resources" });
  return useQuery(
    ["imagePreview", account, imageSlug],
    () => {
      if (!imageSlug) return;
      return request({
        url: `/image/${account}/${imageSlug}?size=${size}`,
        responseType: "blob",
      });
    },
    {
      refetchInterval: false,
      select: (response) => {
        // convert raw blob data to a URL that can be used by img.src
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(response.data);
      },
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useFetchFolders = ({
  type = "images",
  account = null,
  refetch = true,
  callback,
}) => {
  const isMutating = useIsMutating({ mutationKey: "resourcesFolders" });
  return useQuery(
    ["resourceFolders", type, account],
    () =>
      request({
        url: `/resources/folders/${type}`,
        params: { account: account },
      }),
    {
      refetchInterval: refetch === false ? false : 30000,
      refetchOnMount: refetch === false ? false : true,
      refetchOnWindowFocus: refetch === false ? false : true,
      select: (data) => {
        return data?.data?.items;
      },
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useCreateFolder = ({
  account = null,
  type = "images",
  callback,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (description) => {
      return request({
        url: `/resources/folders/${type}`,
        method: "post",
        params: { account: account },
        data: { description: description },
        json: true,
      });
    },
    {
      mutationKey: "resources",
      onSuccess: async (data) => {
        // update data
        const currentFolders = queryClient.getQueryData([
          "resourceFolders",
          type,
          account,
        ])?.data?.items;
        currentFolders.push(data?.data);
        await queryClient.setQueryData(["resourceFolders", type, account], {
          data: { items: [...currentFolders] },
        });
        queryClient.invalidateQueries(["resourceFolders", type, account]);
        if (callback && typeof callback != "undefined") callback(data?.data);
      },
    }
  );
};

export const useDeleteFolder = ({
  account = null,
  type = "images",
  callback,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => {
      return request({
        url: `/resources/folders/${id}`,
        method: "delete",
        params: { account: account },
        json: true,
      });
    },
    {
      mutationKey: "resources",
      onMutate: async (id) => {
        // optimistically update data
        const currentFolders = queryClient.getQueryData([
          "resourceFolders",
          type,
          account,
        ])?.data?.items;
        const deleteIndex = currentFolders.findIndex(
          (folder) => folder?.folderId === id
        );
        currentFolders.splice(deleteIndex, 1);
        await queryClient.setQueryData(["resourceFolders", type, account], {
          data: { items: [...currentFolders] },
        });
      },
      onSettled: async (data) => {
        queryClient.invalidateQueries(["resourceFolders", type, account]);
        if (callback && typeof callback != "undefined") callback(data?.data);
      },
    }
  );
};

export const useDeleteImage = ({
  account = null,
  type = "image",
  callback,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (resourceId) => {
      return request({
        url: `/resources/${type}/${resourceId}`,
        method: "delete",
        params: { account: account },
      });
    },
    {
      mutationKey: "resources",
      onMutate: async (resourceId) => {
        await queryClient.cancelQueries(["resources", type, account]);
        const currentResources = queryClient.getQueryData([
          "resources",
          type,
          account,
        ])?.data?.items;
        // optimistic update data
        if (currentResources?.length > 0) {
          const deleteIndex = currentResources.findIndex(
            (resource) => resource?.imageId === resourceId
          );
          currentResources.splice(deleteIndex, 1);
          await queryClient.setQueryData(["resources", type, account], {
            data: { items: [...currentResources] },
          });
          queryClient.invalidateQueries(["resources", type, account]);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["resources", type, account]);
      },
      onSuccess: callback,
    }
  );
};

export default useFetchResources;
