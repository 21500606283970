import { useState, useEffect } from "react";
import { Pencil, ArrowUp, ArrowDown, TrashCan, CirclePlus } from "akar-icons";
import { confirmDialog } from "primereact/confirmdialog";
import FormItemEdit from "./formItemEdit";
import {
  useDeleteFormField,
  useUpdateForm,
  useCreateFormField,
} from "../../hooks/fetch/useFetchForms";
import useStore from "../../hooks/useStore";

const FormEditIcons = ({
  fieldId,
  formId,
  formSections: formSectionsInit,
  onSetSections,
  onBusy,
  onCompleted,
}) => {
  const [action, setAction] = useState();
  const pathParts = useStore((state) => state.getPathParts());
  const account = pathParts[0];
  const formSections =
    (Array.isArray(formSectionsInit) && [...formSectionsInit]) || [];

  const { mutate: deleteField } = useDeleteFormField({ account });
  const { mutate: editForm, isLoading: isSaving } = useUpdateForm({ account });
  const { mutate: createField, isLoading: isCreating } = useCreateFormField({
    account,
    callback: doInsertCallback,
  });

  const isBusy = !!(isCreating || isSaving);
  useEffect(() => {
    isBusy ? onBusy && onBusy() : onCompleted && onCompleted();
  }, [isBusy]);

  function getField(name) {
    let foundParentObject = null;
    let foundObject = null;
    let foundIndex = -1;
    let foundParentIndex = -1;

    formSections.find((section, parentIndex) => {
      foundIndex = section.items.findIndex((item) => item.name === name);
      if (foundIndex !== -1) {
        foundParentObject = section;
        foundObject = section.items[foundIndex];
        foundParentIndex = parentIndex;
        return true;
      }
      return false;
    });

    return {
      parentObject: foundParentObject,
      object: foundObject,
      parentIndex: foundParentIndex,
      index: foundIndex,
    };
  }
  const field = getField(fieldId);

  const doEdit = () => {
    setAction("edit");
  };
  const doEditUpdate = (fieldObj) => {
    if (field) {
      field.parentObject.items.splice(field.index, 1, fieldObj);
      onSetSections([...formSections]);
    }
  };

  const doDelete = () => {
    confirmDialog({
      message: "Are you sure you wish to delete this form field?",
      accept: () => {
        if (field) {
          deleteField(fieldId);
          field.parentObject.items.splice(field.index, 1);
          if (field.parentObject.items.length === 0) {
            formSections.splice(field.parentIndex, 1);
          }
          onSetSections([...formSections]);
        }
      },
      className: "confirm-dialog",
      acceptClassName: "button",
      rejectClassName: "button button__invert",
    });
  };
  const doInsert = () => {
    const newField = {
      label: "New Field",
      type: "text",
      required: false,
    };
    createField({ id: formId, afterId: fieldId, ...newField });
  };

  function doInsertCallback(data) {
    const currentFieldIndex =
      Array.isArray(data?.data) &&
      data?.data?.findIndex((item) => item.fieldId === fieldId);

    if (currentFieldIndex > -1) {
      const newItem = data?.data[currentFieldIndex + 1];
      if (newItem) {
        newItem.name = newItem.fieldId;
        const newSection = {
          items: [{ ...newItem }],
        };
        formSections.splice(field.parentIndex + 1, 1, newSection);
        onSetSections([...formSections]);
      }
    }
  }

  const doMove = (direction = "up") => {
    if (field) {
      let newIndex =
        direction === "up" ? field.parentIndex - 1 : field.parentIndex + 1;
      if (newIndex < 0) newIndex = formSections.length;
      else if (newIndex >= formSections.length) newIndex = 0;

      const item = formSections.splice(field.parentIndex, 1)[0];
      formSections.splice(newIndex, 0, item);

      // get array of new field positions to patch to API
      const newFieldPositions = [];
      formSections.forEach((section) => {
        section.items.forEach((item) => {
          newFieldPositions.push(item.name);
        });
      });
      editForm({ id: formId, fields: newFieldPositions });
      onSetSections([...formSections]);
    }
  };

  return (
    <>
      <div className="edit-icons">
        <button
          className="button button__condensed"
          aria-label="Edit form field"
          title="Edit"
          onClick={doEdit}
        >
          <Pencil size={22} strokeWidth={1} />
        </button>
        <button
          className="button button__condensed"
          aria-label="Delete form field"
          title="Delete"
          onClick={doDelete}
        >
          <TrashCan size={22} strokeWidth={1} />
        </button>
        <button
          className="button button__condensed"
          aria-label="Move form field up"
          title="Move up"
          onClick={() => {
            doMove("up");
          }}
        >
          <ArrowUp size={22} strokeWidth={1} />
        </button>
        <button
          className="button button__condensed"
          aria-label="Move form field down"
          title="Move down"
          onClick={() => {
            doMove("down");
          }}
        >
          <ArrowDown size={22} strokeWidth={1} />
        </button>
        <button
          className="button button__condensed"
          aria-label="Insert a new form field after this one"
          title="Insert after"
          onClick={doInsert}
        >
          <CirclePlus size={22} strokeWidth={1} />
        </button>
      </div>
      {action && (
        <FormItemEdit
          id={fieldId}
          formId={formId}
          create={false}
          onClose={() => {
            setAction(null);
          }}
          onSave={doEditUpdate}
        />
      )}
    </>
  );
};

export default FormEditIcons;
