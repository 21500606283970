[
  {
    "level": 0.3,
    "description": "Guest",
    "colour": "#aaa",
    "symbol": "G"
  },
  {
    "level": 0.5,
    "description": "Payroll",
    "colour": "#34a853",
    "symbol": "P"
  },
  {
    "level": 1,
    "description": "Employee"
  },
  {
    "level": 2,
    "description": "Manager",
    "colour": "#2d8ada",
    "symbol": "M"
  },
  {
    "level": 2.5,
    "description": "Manager Plus",
    "colour": "#2d8ada",
    "symbol": "M+"
  },
  {
    "level": 3,
    "description": "HR",
    "colour": "#ad77ca",
    "symbol": "HR"
  },
  {
    "level": 3.2,
    "description": "Super HR",
    "colour": "#ad77ca",
    "symbol": "HR+"
  },
  {
    "level": 3.6,
    "description": "Director",
    "colour": "#ea4335",
    "symbol": "D"
  },
  {
    "level": 4,
    "description": "Administrator",
    "colour": "#fabf13",
    "symbol": "★"
  },
  {
    "level": 6,
    "description": "Developer",
    "colour": "#e94186",
    "symbol": "</>"
  }
]
