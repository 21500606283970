import Radio from "./radio";

const AbsenceTypePicker = ({ name, register, validation, setValue, value }) => {
  const values = [
    {
      label: "Holiday",
      value: "holiday",
    },
    {
      label: "Sickness",
      value: "sickness",
    },
    {
      label: "Family",
      value: "family",
    },
    {
      label: "Other",
      value: "other",
    },
  ];
  const className = "absence-type-picker";
  return (
    <div className={className}>
      {values.map((type, key) => {
        const thisId = `absence-${key}-${type?.value}` + Math.random;
        return (
          <label key={thisId} htmlFor={thisId}>
            <Radio
              id={thisId}
              name={name}
              value={type?.value}
              checked={type?.value === value}
              setValue={setValue}
              register={register}
              validation={validation}
            />{" "}
            {type?.label}
          </label>
        );
      })}
    </div>
  );
};

export default AbsenceTypePicker;
