import("../../../scss/components/throbber.scss");
const Throbber = () => {
  return (
    <div className="throbber" role="alertdialog" aria-live="assertive">
      <div className="throbber-element" aria-hidden="true">
        <div className="throbber-triangle"></div>
        <div className="throbber-triangle"></div>
      </div>
    </div>
  );
};

export default Throbber;
