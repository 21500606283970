// This hook manages the focus state for a component. When mounted/displayed,
// the component will be tracked as focused, and when the user clicks outside
// of the component, it will be unfocused, and trigger a callback (e.g. to hide
// it). Example would be a dropdown menu etc

import { useEffect, useRef, useCallback, useState } from "react";

const useFocusComponent = (callback) => {
  // Create a ref to hold the reference to the component
  const componentRef = useRef(null);

  // Local state to manage the focused state of the component
  const [isFocused, setIsFocused] = useState(false);

  // Callback to handle outside clicks and trigger the provided callback
  const doOutsideClick = useCallback(
    (event) => {
      // Check if the component is focused and if the click is outside the component
      if (
        isFocused &&
        componentRef.current &&
        document.body.contains(event.target) &&
        !componentRef.current.contains(event.target) &&
        componentRef.current !== event.target
      ) {
        // Trigger the provided callback on an outside click
        callback?.();
        // Set the focused state to false when clicking outside the component
        setIsFocused(false);
      }
    },
    [isFocused, callback]
  );

  useEffect(() => {
    // Event listener for outside clicks
    const handleOutsideClick = (event) => {
      doOutsideClick(event);
    };

    // Add event listener only when the component is focused
    if (isFocused) {
      document.body.addEventListener("click", handleOutsideClick);
    }

    // Cleanup: remove the event listener when the component unmounts or loses focus
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [doOutsideClick, isFocused]);

  // Function to set the focused state to true
  const setFocused = () => {
    setIsFocused(true);
  };

  // Return an object with the componentRef and a function to set the focused state
  return {
    ref: componentRef,
    setFocused,
  };
};

export default useFocusComponent;
