import React, { useState, useEffect } from "react";

import Drawer from "../../drawer";
import utils from "../../../utilities";
import mapFields from "../../json/fieldMapping.json";

import {
  useFetchForm,
  useUpdateFormField,
} from "../../hooks/fetch/useFetchForms";
import useStore from "../../hooks/useStore";

let fieldMappingSelect = mapFields.map((field) => {
  return {
    value: field.mapToPath,
    label: field.label,
  };
});

fieldMappingSelect = [
  {
    value: "",
    label: "None",
  },
  ...fieldMappingSelect,
];

const getMappedField = (mapToField) => {
  const field = fieldMappingSelect.find((field) => {
    return field.value === mapToField;
  });
  if (!field) return null;
  return {
    value: field.value,
    label: field.label,
  };
};

const FormItemEdit = ({
  id,
  formId,
  create,
  onSave,
  onClose,
  onBusy,
  onCompleted,
}) => {
  if (!id && !create) return <></>;
  const pathParts = useStore((state) => state.getPathParts());
  const account = pathParts[0];
  const { data: form, isLoading } = useFetchForm({
    account,
    formId,
  });
  const { mutate: saveField, isLoading: isSaving } = useUpdateFormField({
    account,
    formId,
    callback: () => {
      setCloseSelf(true);
    },
  });
  const [closeSelf, setCloseSelf] = useState(false);

  const field = form && form.fields?.find((f) => f.fieldId === id);

  const doSave = (data) => {
    data.mapToField = data.mapToField?.value;
    data.id = id;
    data.type = data.type?.value;
    onSave({ ...data, name: data.id });
    saveField(data);
  };

  const isBusy = !!(isLoading || isSaving);
  useEffect(() => {
    isBusy ? onBusy && onBusy() : onCompleted && onCompleted();
  }, [isBusy]);

  const fieldOptions = [
    { value: "text", label: "Text" },
    { value: "textarea", label: "Textarea" },
    { value: "select", label: "Select" },
    { value: "checkbox", label: "Checkbox" },
    { value: "multiCheckbox", label: "Select many" },
    { value: "radio", label: "Select one" },
    { value: "date", label: "Date" },
    { value: "file", label: "File" },
    { value: "number", label: "Number" },
    { value: "email", label: "Email" },
    { value: "hidden", label: "Hidden" },
    { value: "section", label: "Section divider" },
    { value: "page", label: "Page divider" },
  ];

  const sections = [
    {
      items: [
        {
          type: "text",
          label: "Label",
          name: "label",
          required: true,
          default: field?.label,
        },
        {
          type: "select",
          label: "Type",
          name: "type",
          required: true,
          options: fieldOptions,
          creatable: true,
          default: {
            value: field?.type,
            label: fieldOptions.find((o) => {
              return o.value === field?.type;
            })?.label,
          },
        },
        {
          type: "select",
          label: "Options",
          name: "options",
          required: false,
          multi: true,
          creatable: true,
          options: utils.generateSelectOptions(field?.options),
          value: utils.generateSelectOptions(field?.options),
        },
        {
          type: "checkbox",
          label: "Required",
          name: "required",
          default: !!field?.required,
        },
        {
          type: "textarea",
          label: "Caption",
          name: "caption",
          default: field?.caption,
        },
        {
          type: "text",
          label: "Balloon tip",
          name: "balloonTip",
          default: field?.balloonTip,
        },
        {
          name: "mapToField",
          label: "Map to user profile",
          type: "select",
          value: getMappedField(field?.mapToField),
          options: fieldMappingSelect,
        },
      ],
    },
  ];

  const drawerProps = {
    heading: "Edit Field",
    component: "form",
    componentProps: {
      sections,
    },
    close: closeSelf,
    busy: isBusy,
    checkForUnsavedChanges: false,
    onClose,
    onSave: doSave,
  };
  return <Drawer {...drawerProps} />;
};

export default FormItemEdit;
