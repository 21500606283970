import axios from "axios";

const client = axios.create({ baseURL: process.env.API_URL });

// client.interceptors.request.use((config) => {
//   console.log("Request Interceptor:");
//   console.log("URL:", config.url);
//   console.log("Method:", config.method);
//   console.log("Headers:", config.headers);
//   console.log("Data:", config.data);
//   console.log("Params:", config.params);

//   return config;
// });

export const request = ({ ...options }) => {
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    client.defaults.headers.common["x-auth-token"] = authToken;
  }
  const onSuccess = (response) => {
    if (response?.status === 204) {
      errorMsg = "Page has been temporarily unpublished";
      throw {
        toasterMessage: null,
        message: errorMsg,
        status: 204,
        response: {
          status: 204,
        },
      };
    }
    const serverDownElement = document.getElementById("server-down");
    if (serverDownElement) {
      serverDownElement.remove();
    }
    return response;
  };
  const onError = (error) => {
    const errorMessage =
      error?.response?.data?.message || error?.response?.data;
    const bodyError = errorMessage
      ?.toString()
      ?.replace(/(<([^>]+)>)/gi, "")
      ?.replace(/[\r\n]+/g, " ");
    if (error?.code === "ERR_NETWORK") {
      errorMsg = "Network error - please check your connection.";
    } else
      switch (error?.response?.status) {
        case 204:
          options.noToaster = true;
          errorMsg = "Page has been temporarily unpublished";
          break;
        case 400:
          if (bodyError?.includes("is already taken")) {
            options.noToaster = true;
          }
          errorMsg = "Invalid submission - please check details";
          break;
        case 401:
        case 403:
          // is the response type JSON? If so, return as if successful
          const contentType = error?.response?.headers?.["content-type"];
          // does contentType contain "application/json"?
          if (contentType?.includes("application/json")) {
            return error?.response?.data;
          } else
            errorMsg = options.noToaster
              ? "Not authenticated to view this page"
              : "Unauthorised - please check your account privileges";
          break;
        case 404:
          options.noToaster = true;
          errorMsg = "Page not found";
          break;
        case 409:
          errorMsg =
            "Item already exists - please check details before resubmitting";
          break;
        case 413:
          errorMsg = "Too much data is being sent - rejected by server";
          break;
        case 503:
          errorMsg = null;
          options.noToaster = true;
          break;
        default:
          errorMsg =
            "Server error. If this error persists, please contact support.";
          break;
      }

    throw {
      toasterMessage: !options.noToaster && errorMsg,
      message: errorMsg,
      status: error?.response?.status,
      bodyError,
    };
  };
  return client(options).then(onSuccess).catch(onError);
};
