import { useState, useEffect } from "react";
import ImageBrowse from "./imageBrowse";
import Select from "react-select";
import { Tag } from "akar-icons";
import {
  useFetchResources,
  useFetchFolders,
} from "../hooks/fetch/useFetchResources";

const ImageSelect = ({ account, imageSlug, busy, onSelect = null }) => {
  const [allTags, setAllTags] = useState(null);
  const [filterTags, setFilterTags] = useState(null);

  const { data: images, isImagesPending } = useFetchResources({
    account: account,
  });

  const { data: folders, isTagsPending } = useFetchFolders({
    account: account,
  });

  useEffect(() => {
    let tags = [];
    if (folders) {
      folders?.map((tag) => {
        if (tag.folderId && tag.description && tag.type === "images") {
          tags.push({
            value: tag.folderId,
            label: tag.description,
          });
        }
      });
      setAllTags(tags);
    }
  }, [folders]);

  const doChangeFilters = (tags) => {
    let filterTags = [];
    tags.map((tag) => {
      filterTags.push(tag.value);
    });
    setFilterTags(filterTags);
  };

  const skeleton = isImagesPending || isTagsPending;

  return (
    <>
      <Select
        isMulti
        options={allTags}
        className="select"
        classNamePrefix="select"
        placeholder={
          <div className="select-placeholder">
            <Tag size="16" /> Tags
          </div>
        }
        onChange={doChangeFilters}
      />
      <br />
      <br />
      {account && (
        <ImageBrowse
          type="images"
          data={images}
          selectedImageSlug={imageSlug}
          skeleton={skeleton}
          account={account}
          allowDrop={true}
          editIcons={false}
          showFileUpload={false}
          busy={busy}
          filterTags={filterTags}
          allTags={allTags}
          onClick={onSelect}
          onUpload={null}
          onChangeTags={null}
          onTagCreate={null}
          onDownloadFile={null}
        />
      )}
    </>
  );
};

export default ImageSelect;
