import SVG from "./svg";
import { useEffect, useState } from "react";
const SubmitButton = ({
  label,
  icon,
  disabled: initDisabled,
  isForm,
  onSubmit,
}) => {
  const [disabled, setDisabled] = useState(initDisabled);
  useEffect(() => {
    setDisabled(initDisabled);
  }, [initDisabled]);
  icon = icon || "arrow-right";
  const hoverEffectClass = icon === "tick" ? "icon icon__scale" : "icon";
  return (
    <button
      disabled={disabled}
      aria-label={label}
      className={
        !disabled
          ? "button button__arrow"
          : "button button__arrow button__disabled"
      }
      type={isForm ? "form" : "button"}
      onClick={onSubmit}
    >
      {label}
      <SVG src={icon} className={hoverEffectClass} label={label} />
    </button>
  );
};

export default SubmitButton;
