import { useState, useEffect, useRef } from "react";
import Icon from "./icon";
import IconSelection from "./iconSelection";

const IconPicker = ({ icon, iconStyle, name, setValue, onChange }) => {
  const [currentIcon, setCurrentIcon] = useState(icon);
  const [showIconPicker, setShowIconPicker] = useState(false);
  const iconTrigger = useRef(null);

  const doHidePicker = (e) => {
    if (!e || (iconTrigger?.current && e.relatedTarget !== iconTrigger.current))
      setShowIconPicker(false);
  };
  const doSelectIcon = (e, icon) => {
    setCurrentIcon(icon);
    doHidePicker();
    if (onChange) {
      onChange(e, icon);
    }
  };

  useEffect(() => {
    // const iconStr = JSON.stringify(currentIcon);
    setValue && setValue(`${name}`, currentIcon);
  }, [currentIcon]);

  const blankSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" style={iconStyle}></svg>
  );

  return (
    <div className="icon-picker-container">
      <button
        type="button"
        className="button-link"
        ref={iconTrigger}
        onClick={() => {
          setShowIconPicker(!showIconPicker);
        }}
      >
        {currentIcon?.type ? (
          <Icon
            type={currentIcon?.type}
            src={currentIcon?.icon}
            iconStyle={iconStyle}
          />
        ) : (
          blankSvg
        )}
      </button>

      {showIconPicker && (
        <IconSelection onHide={doHidePicker} onSelect={doSelectIcon} />
      )}
    </div>
  );
};

export default IconPicker;
