import { useState, useEffect } from "react";

import { decode } from "html-entities";

import utils from "../../../utilities";
import Conversation from "./inboxConversation";

import {
  useFetchInbox,
  useFetchConversation,
} from "../../hooks/fetch/useFetchMessages";

import "../../../../scss/components/messages.scss";

// Helper function to scroll to the bottom of the conversation when opened
const scrollToBottom = () => {
  setTimeout(() => {
    const messages = document.querySelector(".drawer--content");
    if (messages) messages.scrollTop = messages.scrollHeight;
  }, 100);
};

const Inbox = ({
  backTrigger,
  isSystemMessages,
  onBackLink,
  onBusy = false,
}) => {
  // If set, holds the userId of the conversation to load
  const [conversationUser, setConversationUser] = useState(
    isSystemMessages ? 0 : null
  );

  // If the backTrigger is set, reset the conversationUser to return to inbox
  useEffect(() => {
    if (backTrigger) {
      setConversationUser(null);
    }
  }, [backTrigger]);

  // Fetch data hooks

  const { data: inbox } = useFetchInbox({});

  const { data: conversation, isLoading: isConversationLoading } =
    useFetchConversation({
      userId: conversationUser,
    });

  // If a conversation is loaded, render the conversation component
  useEffect(() => {
    if (conversationUser !== null) {
      scrollToBottom();
      if (onBackLink) onBackLink(true);
    }
  }, [conversationUser, isConversationLoading]);
  if (conversationUser !== null) {
    return (
      <Conversation
        messages={conversation}
        recipient={conversationUser}
        loading={isConversationLoading}
        onBusy={onBusy}
      />
    );
  }

  // Remove the back link if no conversation is loaded
  onBackLink(false);

  if (inbox?.length === 0) {
    return (
      <div className="inbox-messages">
        <div className="inbox-message-message">No messages found</div>
      </div>
    );
  }

  return (
    <div className="inbox-messages">
      {inbox?.map((message, index) => (
        <button
          className={
            message.unread > 0
              ? "button-unstyled inbox-message inbox-message__unread"
              : "button-unstyled inbox-message"
          }
          key={index}
          onClick={() => setConversationUser(message.userId)}
        >
          <div className="inbox-message-name">{message.fullName}</div>
          {message.unread > 0 && (
            <div className="inbox-message-unread">{message.unread}</div>
          )}
          <div className="inbox-message-message">
            {decode(
              utils.truncateString(message.message, 500, 0, true)
            ).replaceAll("<br />", " ")}
          </div>
        </button>
      ))}
    </div>
  );
};

export default Inbox;
