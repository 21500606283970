import useStore from "./useStore";
import { useFetchMe } from "./fetch/useFetchUsers";
import { useFetchAccount } from "./fetch/useFetchAccounts";

const useAppState = () => {
  const clientRef = useStore((state) => state.clientRef);
  const currentUserState = useStore((state) => state.currentUser);
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  const isCurrentUserLoadingState = useStore(
    (state) => state.isCurrentUserLoading
  );
  const setIsCurrentUserLoading = useStore(
    (state) => state.setIsCurrentUserLoading
  );
  const settingsState = useStore((state) => state.settings);
  const setSettings = useStore((state) => state.setSettings);
  const isSettingsLoadingState = useStore((state) => state.isSettingsLoading);
  const setIsSettingsLoading = useStore((state) => state.setIsSettingsLoading);

  const { data: user, isLoading: isCurrentUserLoading } = useFetchMe({
    refetch: false,
  });
  const { data: account, isLoading: isSettingsLoading } = useFetchAccount({
    account: clientRef || null,
    refetch: false,
  });

  const hasAbsence = !!(
    account?.settings?.holidayModule && !user?.disableHolidayModule
  );
  const hasEmployee = !!account?.settings?.employmentDb;
  const hasTraining = !!account?.settings?.training;
  const employeeTabs = account?.settings?.dbTabs || {};
  const moduleAccess = account?.settings?.moduleAccess || {};
  const hasEditablePages = !!account?.settings?.hasEditablePages;
  const accessLevel = user?.accessLevel || 0;
  const isPayroll = !!user?.payroll;
  const isManager = user?.accessLevel === 2;
  const isSeniorManager = user?.accessLevel === 2.5;
  const isHR = user?.accessLevel >= 3;

  const isTopDog = user?.accessLevel >= 3.6;
  const isAbsenceManager =
    !!!user?.disableHolidayModule &&
    // (isManager || isSeniorManager) &&
    Array.isArray(user?.groupsDetails) &&
    user?.groupsDetails.length > 0 &&
    user?.groupsDetails.findIndex(
      (group) => group?.absenceApproverAccess === true
    ) > -1;

  const isLineManager = !!(
    isManager &&
    Array.isArray(user?.managedUsers) &&
    user?.managedUsers.filter((user) => {
      return !!user?.enabled;
    }).length > 0
  );

  const currentUser = {
    ...user,
    accessLevel,
    isPayroll,
    isManager,
    isSeniorManager,
    isHR,
    isAbsenceManager,
    isLineManager,
    isTopDog,
  };

  const settings = {
    hasAbsence,
    hasEmployee,
    hasTraining,
    hasEditablePages,
    employeeTabs,
    moduleAccess,
  };

  if (JSON.stringify(currentUserState) !== JSON.stringify(currentUser)) {
    setCurrentUser(currentUser);
  }
  if (isCurrentUserLoadingState !== isCurrentUserLoading) {
    setIsCurrentUserLoading(isCurrentUserLoading);
  }
  if (JSON.stringify(settingsState) !== JSON.stringify(settings)) {
    setSettings(settings);
  }
  if (isSettingsLoadingState !== isSettingsLoading) {
    setIsSettingsLoading(isSettingsLoading);
  }
};

export default useAppState;
