import { useState } from "react";
import SVG from "./common/svg";
import { request } from "../utilities/fetch.js";
import useStore from "./hooks/useStore";

const MainPagePDFButton = () => {
  const [loading, setLoading] = useState(false);
  const pathParts = useStore((state) => state.getPathParts());
  const pageName = pathParts[pathParts.length - 1];
  const pdfURL = `/articles/pdf/${pageName}`;

  const doGetPDF = () => {
    setLoading(true);
    request({
      url: pdfURL,
      params: {
        download: 1,
        format: "pdf",
      },
      responseType: "blob",
    }).then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const filename = `${pageName}.pdf`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <button
      className="button main-content--download-btn"
      aria-label="Download article as PDF"
      title="Download as PDF"
      onClick={doGetPDF}
      disabled={loading}
    >
      {loading ? "Fetching PDF..." : "Download Printable PDF"}
      <SVG
        src="download"
        label="Download"
        className="icon"
        width="1rem"
        height="1rem"
      />
    </button>
  );
};

export default MainPagePDFButton;
