import { useEffect } from "react";
import toast from "react-hot-toast";
import Throbber from "./common/throbber";
import { request } from "../utilities/fetch.js";
import useStore from "./hooks/useStore";
import "../../scss/components/tokenLogin.scss";

const TokenLogin = ({ token, onLogin }) => {
  const subdomain = useStore((state) => state.subdomain);
  const pathAccount = subdomain || "master";

  const isAdmin = useStore((state) => state.getIsAdmin());
  // useEffect(() => {
  //   console.log(process.env.BASE_PATH);
  // }, [isAdmin]);

  const doLogin = async () => {
    tokenDecoded = JSON.parse(atob(token));
    if (tokenDecoded?.username && tokenDecoded?.token) {
      try {
        login = await request({
          url: `/auth/${pathAccount}`,
          method: "POST",
          data: {
            username: tokenDecoded?.username,
            token: tokenDecoded?.token,
          },
        });
        const jwt = login?.data;
        if (jwt && onLogin) {
          onLogin(true, jwt, false);
          window.location.href = "/";
        }
      } catch (err) {
        console.log("Could not log in with token", err);
        toast.error(err?.toasterMessage || "Something went wrong");
        onLogin(false);
        window.location.href = "/";
      }
    }
  };
  useEffect(() => {
    if (token) {
      window.setTimeout(doLogin, 1000);
    }
  }, [token]);
  return (
    <div className="token-login-container">
      <Throbber />
    </div>
  );
};

export default TokenLogin;
