import { useState, useEffect, useRef } from "react";
import { BlockPicker } from "react-color";
import useFocusComponent from "../hooks/useFocusComponent";

const ColourPicker = ({
  name,
  colour: initColour,
  register,
  setValue,
  disabled: initDisabled,
}) => {
  const { ref: focusedRef, setFocused } = useFocusComponent(() => {
    setPickerVisible(false);
  });

  const [pickerVisible, setPickerVisible] = useState(false);
  const [colour, setColour] = useState(initColour);
  const [disabled, setDisabled] = useState(initDisabled);
  // const colourPicker = useRef(null);
  const doShowPicker = () => {
    setPickerVisible(true);
    setFocused();
    if (!pickerVisible) {
      focusedRef.current.focus();
    }
  };
  const doChange = (colour, e) => {
    const colourStr = colour?.hex ? colour.hex : colour?.toString();
    setColour(colourStr);
    if (e?.type === "click") setPickerVisible(false);
    setValue && setValue(`${name}`, colourStr);
  };

  useEffect(() => {
    doChange(initColour);
  }, [initColour]);

  useEffect(() => {
    setDisabled(initDisabled);
  }, [initDisabled]);

  let buttonStyle = {
    backgroundColor: colour,
  };
  const pickerClass = "input-colour-picker" + (disabled ? " disabled" : "");
  return (
    <>
      <div className={pickerClass}>
        <button
          className="input-colour-picker--swatch"
          style={buttonStyle}
          type="button"
          onClick={disabled ? null : doShowPicker}
        ></button>
        <div
          ref={focusedRef}
          className="input-colour-picker--picker"
          id="input-colour-picker"
        >
          {pickerVisible && (
            <BlockPicker color={colour} onChangeComplete={doChange} />
          )}
        </div>
        {register && (
          <input
            name={name}
            type="hidden"
            defaultValue={colour}
            {...register(name, {
              required: {
                value: false,
              },
            })}
          />
        )}
      </div>
    </>
  );
};

export default ColourPicker;
