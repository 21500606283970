const Info = ({ data }) => {
  return (
    <div className="data-table">
      {data?.map((row) => (
        <>
          <div className="data-table--label">{row.label}</div>
          <div className="data-table--value">{row.value}</div>
          <hr className="data-table--divide" />
        </>
      ))}
    </div>
  );
};

export default Info;
