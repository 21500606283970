import { decode } from "html-entities";
import { Cross } from "akar-icons";

import {
  useFetchNotifications,
  useDeleteNotification,
} from "../../hooks/fetch/useFetchMessages";

import "../../../../scss/components/messages.scss";

const parseNotification = (content) => {
  const decoded = decode(content);
  // convert URLs to links
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return decoded?.replace(urlRegex, (url) => {
    // if the URL contains '#'
    let linkText = url;
    if (url.includes("#")) {
      linkText = "Go to absence";
    }
    return `<a href="${url}">${linkText}</a>`;
  });
};

const Notifications = ({}) => {
  // Fetch data hooks
  const { data: notifications } = useFetchNotifications({});
  const { mutate: doDelete } = useDeleteNotification({});

  if (notifications?.length === 0) {
    return (
      <div className="inbox-messages">
        <div className="inbox-message-message">You have no notifications</div>
      </div>
    );
  }
  return (
    <div className="inbox-messages">
      {notifications?.map((notification, i) => (
        <div
          className={
            notification.unread > 0
              ? "inbox-message inbox-message__unread"
              : "inbox-message"
          }
          key={i}
        >
          <div
            className={
              notification.unread > 0
                ? "inbox-message-message inbox-message-message__unread"
                : "inbox-message-message"
            }
          >
            <div
              dangerouslySetInnerHTML={
                true && { __html: parseNotification(notification.content) }
              }
            ></div>
            <button
              onClick={() => {
                doDelete(notification.notificationId);
              }}
              className="button button__transparent inbox-message-message--delete"
            >
              <Cross size={16} />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Notifications;
