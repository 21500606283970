import {
  useQuery,
  useMutation,
  useQueryClient,
  useIsMutating,
} from "react-query";
import { request } from "../../../utilities/fetch.js";

export const useFetchAdminConfig = ({ callback }) => {
  const isMutating = useIsMutating({ mutationKey: "adminConfig" });
  return useQuery(
    "adminConfig",
    () => {
      return request({
        url: "/admin/config",
      });
    },
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        return data?.data;
      },
      onSuccess: (data) => {
        if (callback && typeof callback != "undefined") callback(data);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useUpdateAdminConfig = ({ callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      if (!data) return Promise.resolve({});
      return request({
        url: `/admin/config`,
        method: "patch",
        data: data,
        json: true,
      });
    },
    {
      mutationKey: "adminConfig",
      onMutate: async (data) => {
        await queryClient.cancelQueries("adminConfig");
        // optimistic update data
        const currentConfig = queryClient.getQueryData("adminConfig")?.data;
        await queryClient.setQueryData("adminConfig", {
          data: { ...currentConfig, ...data },
        });
        queryClient.invalidateQueries("adminConfig");
        if (callback && typeof callback != "undefined") callback(data);
      },
      onSettled: async () => {
        queryClient.invalidateQueries("adminConfig");
      },
    }
  );
};
