import { useEffect, useRef } from "react";
import useStore from "../../hooks/useStore";
import "../../../../scss/components/formSubmission.scss";

const Submission = ({ submissionId, submission }) => {
  const clientRef = useStore((state) => state.clientRef);
  const authToken = useStore((state) => state.authToken);
  const printComponent = useRef();
  const getField = (field) => {
    switch (field.type) {
      case "file":
        const fileName = (field?.value && field?.value[0]?.fileName) || null;
        if (!fileName) return "No file uploaded";
        return (
          <button
            className="button-link"
            type="button"
            onClick={() => doDownloadFile(field.value[0])}
          >
            {fileName}
          </button>
        );
      default:
        return field.value;
    }
  };

  useEffect(() => {
    document.body.classList.add("print-mode");
  }, []);

  const doDownloadFile = (file) => {
    if (!file) return;
    const fileName = file?.fileName;
    const endpoint = file?.endpoint;
    fetch(`${process.env.API_URL}${endpoint}?account=${clientRef}`, {
      method: "GET",
      headers: {
        "x-auth-token": authToken,
      },
    })
      .then(async (res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("target", "_blank");
        a.setAttribute("download", fileName);
        a.href = url;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const doPrint = () => {
    const printEl = printComponent.current?.cloneNode(true);
    document.getElementById("print-container")?.replaceChildren(printEl);
    window.print();
  };

  return (
    <>
      <button className="button" onClick={doPrint}>
        Print
      </button>
      <table
        className="table table__2-col-split"
        ref={printComponent}
        style={{
          "margin-top": "var(--padding)",
        }}
      >
        {submission?.length > 0 &&
          submission?.map((field) => {
            return (
              <tr key={field.fieldId}>
                <td>{field.label}</td>
                <td>{getField(field)}</td>
              </tr>
            );
          })}
      </table>
    </>
  );
};

export default Submission;
