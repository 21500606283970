import { useEffect, lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useQueryClient } from "react-query";

const Admin = lazy(() => import("./components/admin/admin"));
const Client = lazy(() => import("./components/client"));
import Login from "./login";
import TokenLogin from "./components/tokenLogin";
const ExternalCss = lazy(() => import("./components/common/externalCss"));

import useStore from "./components/hooks/useStore";
import useAppState from "./components/hooks/useAppState";

// check if token has been passed in URL to log in
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");

// add event listener to check for dark mode change
window
  .matchMedia("(prefers-color-scheme: dark)")
  .addEventListener("change", (event) => {
    useStore.getState().setToggleDarkMode(event.matches);
  });
if (localStorage.getItem("toggleDarkMode") === "true")
  document.querySelector(":root").classList.add("theme-dark");

const App = () => {
  useAppState();
  const setAuthToken = useStore((state) => state.setAuthToken);
  const subdomain = useStore((state) => state.subdomain);
  const clientRef = useStore((state) => state.clientRef);
  const clientDetails = useStore((state) => state.clientDetails);
  const baseURL = useStore((state) => state.baseURL);
  const loggedIn = useStore((state) => state.loggedIn);
  const setLoggedIn = useStore((state) => state.setLoggedIn);
  const toggleDarkMode = useStore((state) => state.toggleDarkMode);
  const toggleHighContrast = useStore((state) => state.toggleHighContrast);

  useEffect(() => {
    if (toggleDarkMode)
      document.querySelector(":root").classList.add("theme-dark");
    if (toggleHighContrast) {
      document.querySelector(":root").classList.add("theme-high-contrast");
      document.querySelector(":root").classList.add("theme-large-text");
    }
  }, []);

  const queryClient = useQueryClient();
  const doLogin = (loggedIn = true, token = null, admin = false) => {
    setLoggedIn(loggedIn);
    const thisToken = loggedIn ? token : null;
    localStorage.setItem("authToken", thisToken);
    localStorage.setItem("loggedIn", loggedIn);
    localStorage.setItem("admin", admin);
    setAuthToken(thisToken);
    // clear UseQuery cache (API calls)
    queryClient.invalidateQueries();
    queryClient.clear();
  };

  // if token has been passed, log out any existing user
  useEffect(() => {
    if (token) {
      doLogin(false);
    }
  }, [token]);

  const parsedCss = JSON.parse(clientDetails)?.theme?.css;

  const clientCss = parsedCss ? (
    <style>{parsedCss}</style>
  ) : clientRef ? (
    <ExternalCss url={`${process.env.API_URL}/css/${clientRef}`} />
  ) : null;

  // ADMIN
  if (subdomain === "admin")
    return (
      <>
        {clientCss}
        {!loggedIn && (
          <Router basename={baseURL}>
            <Login onLogin={doLogin} />
          </Router>
        )}
        {loggedIn && <Admin onLogin={doLogin} />}
      </>
    );

  // CLIENT
  if (token) {
    return <TokenLogin token={token} onLogin={doLogin} />;
  }

  return (
    <>
      {!loggedIn && (
        <Router basename={baseURL}>
          <Login onLogin={doLogin} />
        </Router>
      )}

      {clientCss}

      {loggedIn && <Client onLogin={doLogin} />}
    </>
  );
};

export default App;
