import {
  useQuery,
  useQueryClient,
  useMutation,
  useIsMutating,
} from "react-query";
import { request } from "../../../utilities/fetch.js";
import { transform } from "dot-object";

const transformSubmissionData = (data, formFields) => {
  const formData = new FormData();
  for (const [key, field] of Object.entries(data)) {
    if (key !== "id") {
      // get form field
      const formField = formFields?.find((f) => f.fieldId === key);
      if (field instanceof FileList) {
        // Handle file uploads separately
        Array.from(field).forEach((file, index) => {
          formData.append(`${key}_${index}`, file);
        });
      } else if (formField?.type === "multiCheckbox") {
        let value = "";
        field.forEach((item, index) => {
          value += item.label + ", ";
        });
        value = value.slice(0, -2);
        formData.append(key, value);
      } else {
        formData.append(key, field?.value || field);
      }
    }
  }
  return formData;
};

export const useFetchForms = ({ account = null, callback, refetch = true }) => {
  const isMutating = useIsMutating({ mutationKey: "forms" });
  return useQuery(
    ["forms", account],
    () => {
      if (account === false) return Promise.resolve({});
      return request({ url: "/forms", params: { account: account } });
    },
    {
      refetchInterval: refetch === false ? false : 30000,
      refetchOnMount: refetch === false ? false : true,
      refetchOnWindowFocus: refetch === false ? false : true,
      select: (data) => data?.data?.items,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};
export const useFetchSubmissions = ({ account = null, formId, callback }) => {
  const isMutating = useIsMutating({ mutationKey: "submissions" });
  return useQuery(
    ["submissions", formId, account],
    () => {
      if (!formId || account === false) return Promise.resolve({});
      return request({
        url: `/submissions/${formId}`,
        params: { account: account },
      });
    },
    {
      refetchInterval: 30000,
      select: (data) => data?.data?.items,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};
export const useFetchUserSubmissions = ({
  account = null,
  userId,
  callback,
}) => {
  const isMutating = useIsMutating({ mutationKey: "submissions" });
  return useQuery(
    ["submissions", userId, account],
    () => {
      if (!userId) return Promise.resolve({});
      return request({
        url: `/submissions/user/${userId}`,
        params: { account: account },
      });
    },
    {
      refetchInterval: 30000,
      select: (data) => data?.data?.items,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};
export const useFetchSubmission = ({
  account = null,
  submissionId,
  callback,
}) => {
  const isMutating = useIsMutating({ mutationKey: "submissions" });
  return useQuery(
    ["submission", submissionId, account],
    () => {
      if (!submissionId) return Promise.resolve({});
      return request({
        url: `/submission/${submissionId}`,
        params: { account: account },
      });
    },
    {
      refetchInterval: 30000,
      select: (data) => data?.data,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};
export const useFetchForm = ({ account = null, formId, callback }) => {
  const isMutating = useIsMutating({ mutationKey: "forms" });
  return useQuery(
    ["form", account, formId],
    () => {
      if (!formId) {
        return Promise.resolve({});
      }
      return request({ url: `/forms/${formId}`, params: { account: account } });
    },
    {
      refetchInterval: false,
      select: (data) => data?.data,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};
export const useFetchFormFields = ({ account = null, callback }) => {
  const isMutating = useIsMutating({ mutationKey: "formFields" });
  return useQuery(
    ["formFields", account],
    () => {
      return request({ url: `/form-fields`, params: { account: account } });
    },
    {
      refetchInterval: false,
      select: (data) => data?.data?.items,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useFetchFormField = ({
  account = null,
  callback,
  fieldId = null,
}) => {
  const isMutating = useIsMutating({ mutationKey: "formFields" });
  return useQuery(
    ["formField", fieldId, account],
    () => {
      if (!fieldId) {
        return Promise.resolve({});
      }
      return request({
        url: `/form-fields/${fieldId}`,
        params: { account: account },
      });
    },
    {
      refetchInterval: false,
      select: (data) => data?.data,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useCreateForm = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return request({
        url: `/forms`,
        method: "post",
        data: data,
        params: { account: account },
      });
    },
    {
      mutationKey: "forms",
      onMutate: async (data) => {
        await queryClient.cancelQueries(["forms", account]);
      },
      onSettled: (data) => {
        queryClient.invalidateQueries(["forms", account]);
      },
      onSuccess: callback,
    }
  );
};

export const useDuplicateForm = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => {
      return request({
        url: `/forms/duplicate/${id}`,
        method: "get",
        params: { account: account },
      });
    },
    {
      mutationKey: "forms",
      onMutate: async (data) => {
        await queryClient.cancelQueries(["forms", account]);
      },
      onSettled: (data) => {
        queryClient.invalidateQueries(["forms", account]);
      },
      onSuccess: callback,
    }
  );
};

export const useDeleteForm = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (formId) => {
      return request({
        url: `/forms/${formId}`,
        method: "delete",
        params: { account: account },
      });
    },
    {
      mutationKey: "forms",
      onMutate: async (formId) => {
        await queryClient.cancelQueries(["forms", account]);
        const currentForms = queryClient.getQueryData(["forms", account])?.data
          ?.items;
        // optimistic update data
        if (currentForms?.length > 0) {
          const deleteIndex = currentForms.findIndex(
            (form) => form?.formId === formId
          );
          currentForms.splice(deleteIndex, 1);
          await queryClient.setQueryData(["forms", account], {
            data: { items: [...currentForms] },
          });
          queryClient.invalidateQueries(["forms", account]);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["forms", account]);
      },
      onSuccess: callback,
    }
  );
};

export const useUpdateForm = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const formId = data?.id;
      if (!formId) return Promise.resolve({});
      const submitData = { ...data };
      delete submitData.id;
      return request({
        url: `/forms/${formId}`,
        method: "patch",
        data: submitData,
        params: { account: account },
      });
    },
    {
      mutationKey: "forms",
      onMutate: async (data) => {
        const formId = data?.id;
        await queryClient.cancelQueries(["form", account, formId]);
        await queryClient.cancelQueries(["forms", account]);
      },
      onSettled: (data) => {
        const formId = data?.data?.formId;
        queryClient.invalidateQueries(["form", account, formId]);
        queryClient.refetchQueries(["form", account, formId]);
        queryClient.invalidateQueries(["forms", account]);
      },
      onSuccess: callback,
    }
  );
};

export const useUpdateFormField = ({
  account = null,
  callback,
  formId = null,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const fieldId = data?.id;
      if (!fieldId) return Promise.resolve({});
      delete data.id;
      if (data.options) {
        if (!Array.isArray(data.options)) {
          data.options = null;
        } else {
          data.options = data.options.map((option) => option.value?.toString());
        }
      }
      return request({
        url: `/form-fields/${fieldId}`,
        method: "patch",
        data: data,
        params: { account: account },
      });
    },
    {
      mutationKey: "forms",
      onMutate: async () => {
        await queryClient.cancelQueries(["form", account, formId]);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["form", account, formId]);
      },
      onSuccess: callback,
    }
  );
};

export const useCreateFormField = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const formId = data?.id;
      if (!formId) return Promise.resolve({});
      delete data.id;
      return request({
        url: `/forms/${formId}`,
        method: "post",
        data: data,
        params: { account: account },
      });
    },
    {
      mutationKey: "forms",
      onMutate: async (data) => {
        const formId = data?.id;
        await queryClient.cancelQueries(["form", account, formId]);
      },
      onSettled: (data) => {
        const formId = data?.id;
        queryClient.invalidateQueries(["form", account, formId]);
      },
      onSuccess: callback,
    }
  );
};

export const useCreateSubmission = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data = {}, formFields = []) => {
      const formId = data?.id;
      if (!formId) return Promise.resolve({});
      delete data.id;
      data = transformSubmissionData(data, formFields);
      return request({
        url: `/submissions/${formId}`,
        method: "post",
        data: data,
        params: { account: account },
      });
    },
    {
      mutationKey: ["submissions"],
      onMutate: async (data) => {
        const userId = data?.userId;
        await queryClient.cancelQueries(["submissions", userId, account]);
      },
      onSettled: (data) => {
        const userId = data?.userId;
        queryClient.invalidateQueries(["submissions", userId, account]);
      },
      onSuccess: callback,
    }
  );
};
export const useUpdateSubmission = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data = {}, formFields = []) => {
      const submissionId = data?.id;
      if (!submissionId) return Promise.resolve({});
      delete data.id;
      data = transformSubmissionData(data, formFields);
      return request({
        url: `/submissions/${submissionId}`,
        method: "patch",
        data: data,
        params: { account: account },
      });
    },
    {
      mutationKey: ["submissions"],
      onMutate: async (data) => {
        const userId = data?.userId;
        await queryClient.cancelQueries(["submissions", userId, account]);
      },
      onSettled: (data) => {
        const userId = data?.userId;
        queryClient.invalidateQueries(["submissions", userId, account]);
      },
      onSuccess: callback,
    }
  );
};

export const useDeleteFormField = ({ account = null, callback, formId }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (fieldId) => {
      return request({
        url: `/form-fields/${fieldId}`,
        method: "delete",
        params: { account: account },
      });
    },
    {
      mutationKey: "forms",
      onMutate: async (fieldId) => {
        await queryClient.cancelQueries(["form", account, formId]);
        const currentFormItems = queryClient.getQueryData([
          "form",
          account,
          formId,
        ])?.data?.fields;
        // optimistic update data
        if (currentFormItems?.length > 0) {
          const deleteIndex = currentFormItems.findIndex(
            (field) => field?.fieldId === fieldId
          );
          currentFormItems.splice(deleteIndex, 1);
          await queryClient.setQueryData(["form", account, formId], {
            data: { fields: [...currentFormItems] },
          });
          queryClient.invalidateQueries(["form", account, formId]);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["form", account, formId]);
      },
      onSuccess: callback,
    }
  );
};

export default useFetchForms;
