import { useState } from "react";
import useStore from "../hooks/useStore";
import "../../../scss/components/devInfo.scss";
import robotHead from "../../../resources/robot-head.png";

const DevInfo = () => {
  const isAdmin = ""; //useStore((state) => state.getIsAdmin());
  const subdomain = ""; //useStore((state) => state.subdomain);
  const clientRef = ""; //useStore((state) => state.clientRef);
  const path = ""; //useStore((state) => state.getPathParts()?.join("/"));
  const [show, setShow] = useState(false);
  if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "beta")
    return <></>;
  return (
    <div
      className="dev-info"
      data-show={show}
      onClick={() => {
        setShow(!show);
      }}
    >
      {show ? (
        <>
          <div className="dev-info--item">
            <span className="dev-info--item--title">isAdmin:</span>
            <span>{isAdmin.toString()}</span>
          </div>
          <div className="dev-info--item">
            <span className="dev-info--item--title">subdomain:</span>
            <span>{subdomain}</span>
          </div>
          <div className="dev-info--item">
            <span className="dev-info--item--title">clientRef:</span>
            <span>{clientRef}</span>
          </div>
          <div className="dev-info--item">
            <span className="dev-info--item--title">path:</span>
            <span>{path}</span>
          </div>
        </>
      ) : (
        <img src={robotHead} alt="Dev info" />
      )}
    </div>
  );
};

export default DevInfo;
