import "../../../scss/components/video.scss";
import { memo } from "react";
import utils from "../../utilities.js";

const YouTubeEmbed = memo(({ url, thumbnail, autoplay }) => {
  if (!url) return <></>;

  const id = utils.extractYoutubeId(url);

  if (thumbnail)
    return (
      <img
        className="video-embed"
        src={`https://i.ytimg.com/vi/${id}/maxresdefault.jpg`}
      />
    );

  const autoplayQs = autoplay ? "&autoplay=1" : "";
  const ytUrl = `https://www.youtube.com/embed/${id}?rel=0${autoplayQs}`;

  return (
    <iframe
      className="video-embed"
      src={ytUrl}
      title="YouTube video player"
      frameborder="0"
      allow="autoplay; fullscreen; encrypted-media"
    ></iframe>
  );
});

export default YouTubeEmbed;
