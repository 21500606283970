import { useRef } from "react";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";

//when dropdown is not manually clicked then it does not scroll into view
onMenuOpen = () => {
  setTimeout(() => {
    const selectedEl = document.getElementsByClassName(
      "select__option--is-selected"
    )[0];
    if (selectedEl) {
      selectedEl.parentNode.scrollTop = selectedEl.offsetTop;
    }
  }, 1);
};

const Select = (props) => {
  const selectRef = useRef(null);
  const currentSelectRef = props?.htmlRef || selectRef;
  const doBlur = () => {
    if (props?.onBlur) {
      props.onBlur(currentSelectRef?.current?.getValue());
    }
  };
  const closeMenuOnSelect = props?.isMulti ? false : true;
  const selectProps = {
    ...props,
    closeMenuOnSelect,
    ignoreAccents: false,
    classNamePrefix: "select",
    isClearable: true,
    ref: currentSelectRef,
    onMenuOpen: onMenuOpen,
    onBlur: doBlur,
  };
  if (props.creatable) {
    return (
      <CreatableSelect
        {...selectProps}
        styles={{
          ...props.styles,
          option: (provided, state) =>
            state.data.__isNew__
              ? {
                  ...provided,
                  ...props.styles,
                  backgroundColor: "var(--colour-buttons) !important",
                  color: "var(--colour-buttons-text) !important",
                  fontWeight: "bold",
                  borderRadius: "var(--border-radius-small)",
                  cursor: "pointer",
                }
              : provided,
        }}
      />
    );
  }
  return <ReactSelect {...selectProps} />;
};

export default Select;
