import SVG from "./svg";
import { useEffect, useState } from "react";
const BackButton = ({ label, disabled: initDisabled, isForm, onClick }) => {
  const [disabled, setDisabled] = useState(initDisabled);
  useEffect(() => {
    setDisabled(initDisabled);
  }, [initDisabled]);
  return (
    <button
      disabled={disabled}
      className={
        !disabled
          ? "button button__invert button__arrow-back"
          : "button button__invert button__arrow-back button__disabled"
      }
      onClick={onClick}
      type={isForm ? "form" : "button"}
    >
      <SVG src="arrow-right" className="icon" label="Back" />
      <span>{label}</span>
    </button>
  );
};

export default BackButton;
