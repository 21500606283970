import { useState, useEffect } from "react";
import { Pencil } from "akar-icons";
import utils from "../../utilities";

const EditableText = ({ value: initValue, truncate, split = 0, onSave }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initValue);
  useEffect(() => {
    setValue(initValue);
  }, [initValue]);
  const doToggleEditing = (editing) => {
    setEditing = editing;
  };
  const doInputChange = (e) => {
    setValue(e.target.value);
  };
  const doKeyPress = (e) => {
    if (e.key === "Enter") {
      doSaveChanges();
    }
  };
  const doSaveChanges = () => {
    setEditing(false);
    onSave && onSave(value);
  };
  const truncatedValue =
    (truncate && utils.truncateString(value, truncate, split)) || value;
  return (
    <div className="editable-text">
      {editing ? (
        <>
          <input
            type="text"
            autoFocus
            defaultValue={value}
            onBlur={doSaveChanges}
            onChange={doInputChange}
            onKeyDown={doKeyPress}
            onFocus={(e) => {
              e.target.select();
            }}
          />
        </>
      ) : (
        <>
          <span
            onClick={() => {
              setEditing(true);
            }}
          >
            {truncatedValue}
          </span>
          <Pencil size={16} />
        </>
      )}
    </div>
  );
};

export default EditableText;
