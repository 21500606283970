import SVG from "./svg";

const FetchError = (props) => {
  const { error, status } = props;
  return (
    <div className="fetch-error">
      {status && <div className="fetch-error--status">Error {status}</div>}
      <SVG src="fetchError" className="fetch-error--image" label={error} />
      {error && <div className="fetch-error--error">{error}</div>}
      <div className="fetch-error--contact-us">
        If you're experiencing problems, please feel free to{" "}
        <a href="#">contact us</a> and we'll look into the issue for you
      </div>
    </div>
  );
};

export default FetchError;
