const _temp0 = require("inline:./arrow-down.svg");
const _temp1 = require("inline:./arrow-right.svg");
const _temp2 = require("inline:./asterisk.svg");
const _temp3 = require("inline:./bold.svg");
const _temp4 = require("inline:./bullets.svg");
const _temp5 = require("inline:./close.svg");
const _temp6 = require("inline:./dark.svg");
const _temp7 = require("inline:./download.svg");
const _temp8 = require("inline:./excel.svg");
const _temp9 = require("inline:./fetchError.svg");
const _temp10 = require("inline:./h2.svg");
const _temp11 = require("inline:./h3.svg");
const _temp12 = require("inline:./highlight.svg");
const _temp13 = require("inline:./home.svg");
const _temp14 = require("inline:./import.svg");
const _temp15 = require("inline:./inset.svg");
const _temp16 = require("inline:./italic.svg");
const _temp17 = require("inline:./jpg.svg");
const _temp18 = require("inline:./light.svg");
const _temp19 = require("inline:./list.svg");
const _temp20 = require("inline:./low-vision.svg");
const _temp21 = require("inline:./menu-dots.svg");
const _temp22 = require("inline:./menu-hr.svg");
const _temp23 = require("inline:./menu-hs.svg");
const _temp24 = require("inline:./menu-ops.svg");
const _temp25 = require("inline:./menu-td.svg");
const _temp26 = require("inline:./messages.svg");
const _temp27 = require("inline:./music.svg");
const _temp28 = require("inline:./notifications.svg");
const _temp29 = require("inline:./outset.svg");
const _temp30 = require("inline:./pdf.svg");
const _temp31 = require("inline:./piechart.svg");
const _temp32 = require("inline:./powerpoint.svg");
const _temp33 = require("inline:./printer.svg");
const _temp34 = require("inline:./profile.svg");
const _temp35 = require("inline:./question.svg");
const _temp36 = require("inline:./rolodex.svg");
const _temp37 = require("inline:./search.svg");
const _temp38 = require("inline:./tableDeleteColumn.svg");
const _temp39 = require("inline:./tableDeleteRow.svg");
const _temp40 = require("inline:./tableInsertColumn.svg");
const _temp41 = require("inline:./tableInsertRow.svg");
const _temp42 = require("inline:./text.svg");
const _temp43 = require("inline:./tick.svg");
const _temp44 = require("inline:./triangles.svg");
const _temp45 = require("inline:./undraw_access_denied_re_awnf.svg");
const _temp46 = require("inline:./undraw_dropdown_menu_re_jqqs.svg");
const _temp47 = require("inline:./undraw_empty_re_opql.svg");
const _temp48 = require("inline:./undraw_engineering_team_a7n2.svg");
const _temp49 = require("inline:./undraw_folder_files_re_2cbm.svg");
const _temp50 = require("inline:./video.svg");
const _temp51 = require("inline:./wellbeing.svg");
const _temp52 = require("inline:./word.svg");
const _temp53 = require("inline:./zip.svg");
module.exports = {
  "arrow-down.svg": _temp0,
  "arrow-right.svg": _temp1,
  "asterisk.svg": _temp2,
  "bold.svg": _temp3,
  "bullets.svg": _temp4,
  "close.svg": _temp5,
  "dark.svg": _temp6,
  "download.svg": _temp7,
  "excel.svg": _temp8,
  "fetchError.svg": _temp9,
  "h2.svg": _temp10,
  "h3.svg": _temp11,
  "highlight.svg": _temp12,
  "home.svg": _temp13,
  "import.svg": _temp14,
  "inset.svg": _temp15,
  "italic.svg": _temp16,
  "jpg.svg": _temp17,
  "light.svg": _temp18,
  "list.svg": _temp19,
  "low-vision.svg": _temp20,
  "menu-dots.svg": _temp21,
  "menu-hr.svg": _temp22,
  "menu-hs.svg": _temp23,
  "menu-ops.svg": _temp24,
  "menu-td.svg": _temp25,
  "messages.svg": _temp26,
  "music.svg": _temp27,
  "notifications.svg": _temp28,
  "outset.svg": _temp29,
  "pdf.svg": _temp30,
  "piechart.svg": _temp31,
  "powerpoint.svg": _temp32,
  "printer.svg": _temp33,
  "profile.svg": _temp34,
  "question.svg": _temp35,
  "rolodex.svg": _temp36,
  "search.svg": _temp37,
  "tableDeleteColumn.svg": _temp38,
  "tableDeleteRow.svg": _temp39,
  "tableInsertColumn.svg": _temp40,
  "tableInsertRow.svg": _temp41,
  "text.svg": _temp42,
  "tick.svg": _temp43,
  "triangles.svg": _temp44,
  "undraw_access_denied_re_awnf.svg": _temp45,
  "undraw_dropdown_menu_re_jqqs.svg": _temp46,
  "undraw_empty_re_opql.svg": _temp47,
  "undraw_engineering_team_a7n2.svg": _temp48,
  "undraw_folder_files_re_2cbm.svg": _temp49,
  "video.svg": _temp50,
  "wellbeing.svg": _temp51,
  "word.svg": _temp52,
  "zip.svg": _temp53
}