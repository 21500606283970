import { DateTime } from "luxon";
import utils from "../../utilities";
import userMapping from "../json/fieldMapping.json";
import { useFetchSubstitutions } from "./fetch/useFetchPages";
import { useFetchUser } from "./fetch/useFetchUsers";
import useStore from "./useStore";

// Custom hook for parsing content with substitutions
const useParseContent = (content, userId = null) => {
  const clientRef = useStore((state) => state.clientRef);
  const { data: substitutions } = useFetchSubstitutions({});
  const { data: user, isLoading: isUserLoading } = useFetchUser({
    account: clientRef,
    userId,
  });
  const currentUser = useStore((state) => state.currentUser);
  const profile = userId ? user : currentUser;

  // Object to store user-specific substitutions
  const userSubstitutions = {};

  userMapping.forEach((mapping) => {
    // Extract the nested property value from current user
    let userPropertyValue = utils.getNested(profile, mapping.mapToPath);

    // Format any date values
    if (mapping.key.endsWith("date")) {
      const date = DateTime.fromISO(userPropertyValue);
      userPropertyValue = date?.toFormat("dd/MM/yyyy");
    }

    // Combine multiple address details into a single string
    if (mapping.key === "address") {
      userPropertyValue = [
        profile?.details?.address,
        profile?.details?.address2,
        profile?.details?.address3,
        profile?.details?.address4,
        profile?.details?.town,
        profile?.details?.county,
        profile?.details?.postcode,
      ]
        .filter(Boolean)
        .join(", ");
    }

    // Store the substituted value in the userSubstitutions object
    userSubstitutions[mapping.key] = userPropertyValue;
  });

  // Combine global substitutions with user-specific substitutions
  const shortcuts = { ...substitutions, ...userSubstitutions };

  // Check if the content is an array and apply substitutions to each item
  if (Array.isArray(content)) {
    return (
      content
        // ?.filter((item) => {
        //   return (
        //     item &&
        //     (item === "string" ||
        //       item?.contentType === "text" ||
        //       !item?.contentType)
        //   );
        // })
        ?.map((item) => {
          if (typeof item === "string") {
            return utils.parseShortcuts(item, shortcuts);
          }
          return {
            ...item,
            content: utils.parseShortcuts(item?.content, shortcuts),
          };
        })
    );
  }

  // Apply substitutions to a string
  return utils.parseShortcuts(content, shortcuts);
};

export default useParseContent;
