import { useState, useEffect } from "react";
const Tickbox = ({
  id,
  name,
  checked: initChecked,
  size: initSize,
  trigger = null,
  setValue,
  required,
  onChange,
  disabled,
}) => {
  const [checked, setChecked] = useState(initChecked);
  useEffect(() => {
    setChecked(!!initChecked);
    setValue && setValue(`${name}`, !!initChecked);
  }, [initChecked, trigger]);
  useEffect(() => {
    const isChecked = checked ? true : false;
    setValue && setValue(`${name}`, !!isChecked);
  }, [checked]);
  const size = initSize ? parseInt(initSize) : 28;

  const doChecked = (checked, e) => {
    const isChecked = checked ? true : false;
    setChecked(isChecked);
    onChange && onChange(isChecked, e);
  };

  return (
    <>
      <label
        htmlFor={id}
        className="button-link tickbox"
        data-disabled={disabled}
      >
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onChange={(e) => {
            doChecked(!checked, e);
          }}
          disabled={disabled}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <rect x="3" y="3" width="18" height="18" rx="4"></rect>
          <path d="M6,13l5,4L18,7"></path>
        </svg>
      </label>
    </>
  );
};

export default Tickbox;
