import {
  useQuery,
  useQueryClient,
  useMutation,
  useIsMutating,
} from "react-query";
import { request } from "../../../utilities/fetch.js";

export const useFetchInbox = ({ callback }) => {
  const isMutating = useIsMutating({ mutationKey: "messagesInbox" });
  return useQuery(
    ["messagesInbox"],
    () => {
      const isAdmin = !!(localStorage.getItem("admin") === "true");
      if (isAdmin) return Promise.resolve({});
      return request({
        url: "/messages/inbox?separateSystemMessages=true",
      });
    },
    {
      refetchInterval: 10000,
      staleTime: 60000,
      select: (data) => data?.data?.items,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useFetchNotifications = ({ callback }) => {
  const isMutating = useIsMutating({ mutationKey: "notifications" });
  return useQuery(
    ["notifications"],
    () => {
      const isAdmin = !!(localStorage.getItem("admin") === "true");
      if (isAdmin) return Promise.resolve({});
      return request({
        url: "/notifications",
      });
    },
    {
      refetchInterval: 10000,
      staleTime: 60000,
      select: (data) => data?.data?.items,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useMarkNotificationsRead = ({ callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return request({
        url: `/notifications/read`,
        method: "post",
      });
    },
    {
      mutationKey: "notifications",
      onMutate: async (data) => {
        await queryClient.cancelQueries(["notifications"]);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["notifications"]);
      },
      onSuccess: () => {
        if (callback && typeof callback != "undefined") callback();
      },
    }
  );
};

export const useDeleteNotification = ({ callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (notificationId) => {
      return request({
        url: `/notifications/${notificationId}`,
        method: "delete",
      });
    },
    {
      mutationKey: "notifications",
      onMutate: async (notificationId) => {
        await queryClient.cancelQueries(["notifications"]);
        const currentNotifications = queryClient.getQueryData([
          "notifications",
        ]);
        // optimistic update data
        if (currentNotifications?.data?.items?.length > 0) {
          const updated = [...currentNotifications.data.items];
          const deleteIndex = updated.findIndex(
            (notification) => notification?.notificationId === notificationId
          );
          updated.splice(deleteIndex, 1);
          await queryClient.setQueryData(["notifications"], {
            data: { items: [...updated] },
          });
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["notifications"]);
      },
      onSuccess: callback,
    }
  );
};

export const useFetchConversation = ({ account = null, callback, userId }) => {
  const queryClient = useQueryClient();
  const isMutating = useIsMutating({ mutationKey: "messagesInbox" });
  return useQuery(
    ["messagesConversation", userId],
    () => {
      if (userId < 0 || userId === null || userId === undefined) {
        return Promise.resolve({});
      }
      return request({
        url: `/messages/conversation/${userId}`,
        params: { account },
      });
    },
    {
      refetchInterval: true,
      staleTime: 10000,
      select: (data) => data?.data?.items,
      onSuccess: async () => {
        await queryClient.invalidateQueries(["messagesInbox"]);
        if (callback) callback();
      },
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useCreateMessage = ({ account = null, userId, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      if (!data) return Promise.resolve({});
      const sendData = {
        ...data,
      };
      delete sendData.id;
      return request({
        url: `/messages/${userId}`,
        params: { account },
        method: "post",
        data: sendData,
      });
    },
    {
      mutationKey: "messagesInbox",
      onMutate: async (data) => {
        await queryClient.cancelQueries(["messagesConversation", userId]);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["messagesConversation", userId]);
      },
      onSuccess: (data) => {
        if (callback && typeof callback != "undefined") callback(data);
      },
    }
  );
};
