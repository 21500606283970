import { useFetchUserAbsences } from "../../hooks/fetch/useFetchAbsence";
import useStore from "../../hooks/useStore";
import { DateTime } from "luxon";
import "../../../../scss/components/absenceList.scss";

const Absences = ({ userId, innerRef = null, heading = null }) => {
  const clientRef = useStore((state) => state.clientRef);
  const { data: userAbsences, isLoading: isAbsenceLoading } =
    useFetchUserAbsences({
      account: clientRef,
      userId: userId,
    });

  const stripZeros = (value) => {
    return value?.toString().replace(/\.0{1,2}\b/, "");
  };
  const addLinebreak = (text) => {
    const firstWord = text?.split(" ")[0];
    const restOfText = text?.split(" ").slice(1).join(" ");
    return (
      <>
        <div>{stripZeros(firstWord)}</div>
        <div>{restOfText}</div>
      </>
    );
  };

  return (
    <div className="absences-list-container" ref={innerRef}>
      <h1 className="print-only">{heading}</h1>
      <div className="absences-list" ref={innerRef}>
        {userAbsences?.items?.map((absence, index) => {
          const startDate = DateTime.fromISO(absence.start)?.toFormat(
            "dd/MM/yyyy"
          );
          const endDate = DateTime.fromISO(absence.end)?.toFormat("dd/MM/yyyy");
          const date =
            startDate === endDate ? startDate : `${startDate} - ${endDate}`;
          const description =
            absence?.type === "bankHoliday"
              ? absence?.description
              : absence.typeFormatted || absence.description;
          let amount = absence?.annualLeaveConsumed;
          if (
            absence.type === "sickness" ||
            absence.type === "family" ||
            absence.type === "other"
          ) {
            amount = absence?.elapsed;
          }
          return (
            <div
              className="absences-list--absence"
              data-type={absence.type}
              key={index}
            >
              <div className="absences-list--absence--elapsed">
                <div className="absence-elapsed-circle">
                  {addLinebreak(amount)}
                </div>
              </div>
              <div className="absences-list--absence--date">{date}</div>
              <div className="absences-list--absence--description">
                {description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Absences;
