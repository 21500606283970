import {
  useQuery,
  useMutation,
  useQueryClient,
  useIsMutating,
} from "react-query";
import { request } from "../../../utilities/fetch.js";

export const useFetchAccounts = ({ callback }) => {
  const isMutating = useIsMutating({ mutationKey: "adminAccounts" });
  return useQuery(
    "adminAccounts",
    () => {
      return request({
        url: "/admin/accounts",
      });
    },
    {
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      select: (data) => {
        return data?.data?.sort((a, b) =>
          a.description
            ?.toLowerCase()
            .localeCompare(b.description?.toLowerCase())
        );
      },
      initialData: () => {
        if (localStorage.getItem("accounts")) {
          return {
            data: JSON.parse(localStorage.getItem("accounts")),
          };
        }
      },
      onSuccess: (data) => {
        localStorage.setItem("accounts", JSON.stringify(data));
        if (callback && typeof callback != "undefined") callback(data);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useFetchAccountsLegacy = ({ callback }) => {
  const isMutating = useIsMutating({ mutationKey: "adminAccountsLegacy" });
  return useQuery(
    "adminAccountsLegacy",
    () => {
      return request({
        url: "/admin/accounts/legacy",
      });
    },
    {
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (!data?.data) {
          data.data = [
            "1stcallproperty",
            "727",
            "a1homecare",
            "acetravel",
            "ackermanpierce",
            "adastrahr",
            "affinitymortgages",
            "ag47group",
            "albon",
            "alladvertising",
            "alphacare",
            "anniehaak",
            "aqua",
            "aspenwaite",
            "ateuk",
            "avionicgroup",
            "awhardy",
            "bbuk",
            "boostball",
            "braxtedpark",
            "brights",
            "careland",
            "caresoft",
            "carsparescheshunt",
            "ccs",
            "cementfields",
            "cgswealth",
            "challenger",
            "charlis",
            "chesterford",
            "childandchild",
            "cisservices",
            "cityandessex",
            "clearpath",
            "clouders",
            "colourstest",
            "corin",
            "coryton",
            "css",
            "cvt",
            "cyclesuk",
            "dandkfacilities",
            "davisconstruction",
            "davonport",
            "dcflooring",
            "demo",
            "demo2",
            "devonports",
            "digit",
            "directpackaging",
            "dpl",
            "ecc",
            "ecuity",
            "eevolution",
            "energygroup",
            "esg",
            "esl",
            "etclay",
            "etec",
            "evergreen",
            "evolve",
            "evospeed",
            "fenchurch",
            "fledgelings",
            "food4u",
            "forgeway",
            "geraldmcdonald",
            "goldsmith",
            "goldwyns",
            "granite",
            "groundtel",
            "growhackney",
            "hairandson",
            "hassengate",
            "hexarad",
            "hmlconcierge",
            "hsdemo",
            "hsmaster",
            "hurstma",
            "hygienecontracts",
            "imageon",
            "ime",
            "imperial",
            "indirock",
            "infrastructurematters",
            "insight",
            "ipeco",
            "jacksonco",
            "javalin",
            "jbuckland",
            "jks",
            "josephlamb",
            "jtspartnership",
            "kemtron",
            "kevinhancock",
            "kirbyscoaches",
            "knightpiesold",
            "kroptek",
            "leballonrouge",
            "liteplan",
            "lituanica",
            "london-drainage",
            "lordsons",
            "lsst",
            "ltc",
            "lumino",
            "m54",
            "magnifire",
            "mannahousing",
            "mark1hire",
            "marshmallow",
            "matthews",
            "mbhs",
            "mcgrath",
            "metalculture",
            "mgreene",
            "midlandsteel",
            "minerva",
            "minerva-analytics",
            "morley",
            "motoraid",
            "mps",
            "multipipe",
            "mws",
            "netransport",
            "newman",
            "newmanfireplaces",
            "niche",
            "noam",
            "nss",
            "oakleafe",
            "oakmont",
            "officeneedsmahomes",
            "openwood",
            "openwoodml",
            "opm",
            "optimum",
            "orsett-surgery",
            "orthosolutions",
            "osea",
            "pandsautomation",
            "patonandlee",
            "pdc",
            "peacefulplace",
            "pfs",
            "phr",
            "pjellis",
            "planetleasing",
            "plastex",
            "plattrushton",
            "postureforwork",
            "potters",
            "prestonrecruitment",
            "primassure",
            "prioritysupport",
            "prolichtuk",
            "raam",
            "railscape",
            "rapiddcs",
            "red",
            "red7marine",
            "redman",
            "rega",
            "revive",
            "richardson",
            "rowlandhall",
            "savills",
            "schako",
            "sech",
            "sekura",
            "sig",
            "silentdiscocompany",
            "solopress",
            "spoonguru",
            "sprintintegration",
            "stadia",
            "staplehall",
            "stevesselfdrive",
            "stockvale",
            "stourgarden",
            "surgicalholdings",
            "tahola",
            "tbl",
            "tcp",
            "technologydesking",
            "tempusfm",
            "thehive",
            "theraggedpriest",
            "thermotraffic",
            "thinkcs",
            "thorpehall",
            "tlm",
            "tlt",
            "tradeplumbing",
            "tssurgery",
            "unisystems",
            "upr",
            "vegnergroup",
            "venables",
            "victa",
            "victamaster",
            "vie",
            "vimpex",
            "wavedata",
            "wcit",
            "wearefred",
            "wellbeing",
            "wetsuitoutlet",
            "wow",
            "yhr",
            "yhrtesting",
          ];
        }

        return ["master", ...data?.data];
      },
      onSuccess: (data) => {
        if (callback && typeof callback != "undefined") callback(data);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useFetchAccount = ({ account = null, callback, retry = true }) => {
  const isMutating = useIsMutating({ mutationKey: "adminAccounts" });
  return useQuery(
    ["account", account],
    () => {
      if (!account || account === "app") {
        return Promise.resolve({});
      }
      return request({
        url: `/account/${account}`,
      });
    },
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: retry,
      select: (data) => {
        return data?.data;
      },
      onSuccess: (data) => {
        if (callback && typeof callback != "undefined") callback(data);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useEditAccount = ({ account = null, callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      let thisAccount = account || data?.account;
      data.account = data.clientRef;
      delete data.clientRef;
      return request({
        url: `/admin/accounts/${thisAccount}`,
        method: "patch",
        data: data,
        json: true,
      });
    },
    {
      mutationKey: "adminAccounts",
      onMutate: async (data) => {
        let thisAccount = account || data?.account;
        await queryClient.cancelQueries(["account", thisAccount]);
        // optimistic update data
        const currentData = queryClient.getQueryData([
          "account",
          thisAccount,
        ])?.data;
        if (currentData?.settings) {
          const dbTabs = currentData?.settings?.dbTabs || {};
          data.settings.dbTabs = {
            ...dbTabs,
            ...data?.settings?.dbTabs,
          };
        }

        await queryClient.setQueryData(["account", thisAccount], {
          data: {
            ...currentData,
            ...data,
          },
        });
        queryClient.invalidateQueries(["account", thisAccount]);

        // update 'accounts' array
        await queryClient.cancelQueries("adminAccounts");
        const updated = queryClient.getQueryData("adminAccounts")?.data;
        const changedAccountIndex = updated?.findIndex(
          (account) => account.account === thisAccount
        );
        if (changedAccountIndex >= 0) {
          updated[changedAccountIndex] = {
            ...updated[changedAccountIndex],
            ...data,
          };
          await queryClient.setQueryData("adminAccounts", {
            data: [...updated],
          });
        }
        queryClient.invalidateQueries("adminAccounts");
      },
      onSettled: (data) => {
        if (callback && typeof callback != "undefined") callback(data);
      },
    }
  );
};

export const useCreateAccount = ({ callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return request({
        url: `/admin/accounts`,
        method: "post",
        data: data,
        json: true,
      });
      return false;
    },
    {
      mutationKey: "adminAccounts",
      onMutate: async (_d, _u, data) => {
        // update 'accounts' array
        await queryClient.cancelQueries("adminAccounts");
        const updated = queryClient.getQueryData("adminAccounts")?.data;
        updated.push({ ...data });
        await queryClient.setQueryData("adminAccounts", {
          data: [...updated],
        });
        queryClient.invalidateQueries("adminAccounts");
      },
      onSettled: (data) => {
        if (callback && typeof callback != "undefined") callback(data);
      },
    }
  );
};

export const useDeleteAccount = ({ callback }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (accountName) => {
      return request({
        url: `/admin/accounts/${accountName}`,
        method: "delete",
      });
    },
    {
      mutationKey: "adminAccounts",
      onMutate: async (accountName) => {
        await queryClient.cancelQueries("account");
        const currentAccounts = queryClient.getQueryData("adminAccounts");
        // optimistic update data
        if (currentAccounts?.data?.length > 0) {
          const updated = [...currentAccounts.data];
          const deleteIndex = updated.findIndex(
            (account) => account?.account === accountName
          );
          updated.splice(deleteIndex, 1);
          await queryClient.setQueryData("adminAccounts", {
            data: [...updated],
          });
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries("adminAccounts");
      },
      onSuccess: callback,
    }
  );
};

export const useAddAccount = () => {};

export default useFetchAccounts;
