import { useState } from "react";

import { decode } from "html-entities";
import { DateTime } from "luxon";

import utils from "../../../utilities";
import Skeleton from "../../../components/common/skeleton";
import SubmitButton from "../../../components/common/submitButton";

import {
  useCreateMessage,
  useFetchConversation,
} from "../../hooks/fetch/useFetchMessages";
import useStore from "../../hooks/useStore";

const today = DateTime.now().toFormat("dd/MM/yyyy");
const yesterday = DateTime.now().minus({ days: 1 }).toFormat("dd/MM/yyyy");

// Helper function to take a given date, and return 'today', 'yesterday', or a formatted date
const formatDate = (dateString) => {
  const dateObj = DateTime.fromISO(dateString);
  if (!dateObj.isValid) return null;

  const formattedDate = dateObj.toFormat("dd/MM/yyyy");
  return formattedDate === today
    ? "Today"
    : formattedDate === yesterday
    ? "Yesterday"
    : formattedDate;
};

const Conversation = ({
  messages: messagesInit,
  recipient,
  loading,
  onBusy,
}) => {
  const clientRef = useStore((state) => state.clientRef);

  // holds the current value of the reply message
  const [reply, setReply] = useState("");

  const { mutate: createMessage } = useCreateMessage({
    userId: recipient,
    account: clientRef,
    callback: () => {
      if (onBusy) onBusy(false);
      setReply("");
    },
  });
  const { data: messages, isLoading: isConversationLoading } =
    useFetchConversation({
      account: clientRef,
      userId: recipient,
    });

  // Helper action functions

  const doReplyChange = (event) => {
    setReply(event.target.value);
  };

  const doSendReply = () => {
    if (reply === "") return;
    if (onBusy) {
      onBusy(true);
    }
    createMessage({ message: reply });
  };

  let conversation = messages || messagesInit;

  return (
    <>
      {loading && <Skeleton type="messages" />}
      <div
        className={
          recipient > 0
            ? "messages-conversation"
            : "messages-conversation messages-conversation__admin"
        }
      >
        {conversation?.map((message, i) => {
          // If conversation is with system, only show sender messages
          if (recipient === 0 && message.isMe) return null;

          // Format date and time to display
          const formattedDate = formatDate(message.dateTime);
          conversation[i].formattedDate = formattedDate;
          const formattedTime = formattedDate
            ? DateTime.fromISO(message.dateTime).toFormat("h:mm a")
            : null;

          // Only show the date if it's different to the previous message
          const shouldShowDate =
            formattedDate &&
            formattedDate !== conversation[i - 1]?.formattedDate;

          return (
            <>
              {shouldShowDate && (
                <div className="messages-conversation-date">
                  {formattedDate}
                </div>
              )}
              <div
                className={
                  message.isMe
                    ? "messages-conversation-message messages-conversation-message__me"
                    : "messages-conversation-message"
                }
                key={i}
              >
                {formattedTime && (
                  <div className="messages-conversation-time">
                    {formattedTime}
                  </div>
                )}
                {message.fromUserId > 0 && (
                  <>
                    {utils
                      .stripTags(decode(message.message), true)
                      ?.split("<br />")
                      ?.map((paragraph, key) => (
                        <>
                          {paragraph &&
                            paragraph !== "&nbsp;" &&
                            paragraph !== " " && <p key={key}>{paragraph}</p>}
                        </>
                      ))}
                  </>
                )}
                {message.fromUserId === 0 && (
                  <div
                    className="inbox-message-message"
                    dangerouslySetInnerHTML={
                      true && { __html: message.message }
                    }
                  ></div>
                )}
              </div>
            </>
          );
        })}
      </div>
      {recipient > 0 && !loading && (
        <div className="messages-reply">
          <textarea
            className="messages-reply-textarea"
            value={reply}
            autoFocus
            onChange={doReplyChange}
          ></textarea>
          <SubmitButton label="Send" onSubmit={doSendReply} />
        </div>
      )}
    </>
  );
};

export default Conversation;
