import SubmitButton from "./submitButton";
import { CircleAlert, Check, Info } from "akar-icons";

const Notice = ({
  type = "alert",
  message,
  buttonLabel = "Submit",
  onClick,
}) => {
  let icon;
  let className = "notice";
  switch (type) {
    case "success":
      icon = <Check width="50" height="50" />;
      break;
    case "info":
      icon = <Info width="50" height="50" />;
      className = "notice notice__invert";
      break;
    case "alert":
      icon = <CircleAlert width="50" height="50" />;
      break;
  }
  return (
    <div className={className}>
      {icon}
      {message}
      {onClick && <SubmitButton label={buttonLabel} onSubmit={onClick} />}
    </div>
  );
};

export default Notice;
