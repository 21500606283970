import React, { useState, useEffect } from "react";
import Error from "./error";
import toast from "react-hot-toast";
import { request } from "../../utilities/fetch";
import useStore from "../hooks/useStore";

import "../../../scss/components/messages.scss";

const MessageUsersForm = ({
  recipients,
  title = null,
  messageInit = "",
  onClose,
  onSendMessage,
}) => {
  const clientRef = useStore((state) => state.clientRef);
  const [message, setMessage] = useState(messageInit);
  const [error, setError] = useState("");
  const [fetching, setFetching] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setMessage(messageInit);
  }, [messageInit]);

  const doChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const doSendMessage = async () => {
    const recipientsStr = recipients.join(",");
    if (!message) return setError("Please enter a message.");
    if (!recipientsStr) return setError("No recipients selected.");
    setFetching(true);
    try {
      await request({
        url: `/messages/${recipientsStr}?account=${clientRef}`,
        method: "POST",
        data: { message },
      });
      // If the request is successful, show a success message
      setSuccess(true);
      setError("");
      setFetching(false);
      if (onSendMessage) onSendMessage();
      toast.success("Message successfully sent");
      if (onClose) {
        setTimeout(() => {
          onClose();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setFetching(false);
      if (err?.status === 400 || err?.status === 404) {
        // Display the specific error message from the API response
        setError(err?.bodyError);
      } else {
        // Display a generic error message for other errors
        setError("An error occurred while processing your request.");
      }
    }
  };

  const buttonClass = fetching ? "button disabled" : "button";

  return (
    <>
      {title === null ? <h2>Message selected users</h2> : <h2>{title}</h2>}
      <form
        className="message-users-form"
        onSubmit={(e) => {
          e.preventDefault();
          doSendMessage();
        }}
      >
        <textarea
          placeholder="Your message"
          value={message}
          autoFocus
          onChange={doChangeMessage}
        ></textarea>
        <button
          className={buttonClass}
          type="button"
          disabled={fetching}
          onClick={doSendMessage}
        >
          {fetching ? "Sending..." : "Send message"}
        </button>
      </form>
      <Error
        message={error}
        type={success ? "success" : null}
        onMessageShown={() => {
          setError("");
          setSuccess("");
        }}
      />
    </>
  );
};

export default MessageUsersForm;
