import { useState, useEffect, useRef } from "react";
import { request } from "../../utilities/fetch.js";
import utils from "../../utilities.js";
import toast from "react-hot-toast";
import Form from "./form";
import SubmitButton from "./submitButton";
import ContractSign from "./contractSign";
import Notice from "./notice";
import { useFetchForm } from "../hooks/fetch/useFetchForms";
import { useFetchUsersBasic } from "../hooks/fetch/useFetchUsers";
import useStore from "../hooks/useStore";

const ApiForm = ({
  id,
  disabled,
  hideSubmit = false,
  isContract = false,
  contractSigned = null,
  onSignContract,
}) => {
  const currentUser = useStore((state) => state.currentUser);
  const clientRef = useStore((state) => state.clientRef);

  const [formFields, setFormFields] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [hasPaging, setHasPaging] = useState(false);
  const { data: form, isLoading } = useFetchForm({
    account: clientRef,
    formId: id,
  });

  const { data: users, isLoading: isUsersLoading } = useFetchUsersBasic({
    account: clientRef,
  });
  const usersSelect = users
    ?.map((option) => {
      return {
        value: option.id,
        label: option.fullName,
      };
    })
    ?.filter((option) => {
      return !!option.value && !!option.label;
    });

  const getMappedField = (mapToField) => {
    if (!mapToField) return null;
    return utils.getNested(currentUser, mapToField);
  };

  useEffect(() => {
    if (form?.formId) {
      const data = form?.fields?.map((field) => {
        if (field.type === "page") setHasPaging(true);
        return {
          items: [
            {
              type: field.type,
              required: field.required,
              label: field.label,
              name: field.fieldId,
              caption: field.caption,
              options: field.options,
              default: getMappedField(field.mapToField),
            },
          ],
        };
      });
      if (
        data?.length > 0 &&
        form?.allowProxySubmit &&
        (currentUser?.isManager || currentUser?.isHR)
      ) {
        data.unshift({
          items: [
            {
              type: "select",
              name: "userId",
              label: "Submit on behalf of user",
              options: usersSelect,
            },
          ],
        });
      }
      if (!formFields || JSON.stringify(data) !== JSON.stringify(formFields)) {
        setFormFields(data);
      }
    }
  }, [form?.formId, users]);

  // store callback for React hook form validation / submission
  // so we can submit form from parent
  const formSubmitCallbackRef = useRef();
  function submitCallback(callback) {
    formSubmitCallbackRef.current = callback;
  }

  const doSubmit = async (data) => {
    setSubmitting(true);
    const formData = new FormData();
    for (const [key, field] of Object.entries(data)) {
      if (key !== "id") {
        // get form field
        const formField = form?.fields?.find((f) => f.fieldId === key);
        if (field instanceof FileList) {
          // Handle file uploads separately
          Array.from(field).forEach((file, index) => {
            formData.append(`${key}_${index}`, file);
          });
        } else if (formField?.type === "multiCheckbox") {
          let value = "";
          field.forEach((item, index) => {
            value += item.label + ", ";
          });
          value = value.slice(0, -2);
          formData.append(key, value);
        } else {
          formData.append(key, field?.value || field);
        }
      }
    }

    try {
      const result = await request({
        url: `/submissions/${form?.formId}`,
        data: formData,
        method: "POST",
        params: {
          account: clientRef,
        },
      });

      if (result.status === 200) {
        setSubmitted(true);
        if (isContract && onSignContract) {
          onSignContract();
        }
      }
    } catch (err) {
      if (err?.toasterMessage) {
        toast.error(`${err.toasterMessage}`);
      }
    }

    setSubmitting(false);
  };

  const busy = !!(isLoading || isUsersLoading);
  const skeleton = !!isLoading;

  if (submitted)
    return !isContract ? (
      <Notice
        type="success"
        message="Thank you - your form submission has been received"
      />
    ) : (
      <></>
    );

  return (
    <>
      <Form
        id={id}
        sections={formFields}
        autoFocus={false}
        ignorePaging={false}
        disabled={busy || submitting || disabled}
        registerCallback={submitCallback}
        onSubmit={doSubmit}
      />
      {!hideSubmit && (isContract || !hasPaging) && (
        <div className="form-submit-container">
          {!isContract && !disabled && !hasPaging && (
            <SubmitButton
              label={submitting ? "Submitting..." : "Submit form"}
              disabled={busy || submitting}
              onSubmit={() => formSubmitCallbackRef.current()}
            />
          )}
          {isContract && !contractSigned && !disabled && (
            <ContractSign
              disabled={busy || submitting}
              onSubmit={() => formSubmitCallbackRef.current()}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ApiForm;
