import defaultLogo from "../../resources/generic-logo.webp";
import adminLogo from "../../resources/logo-admin.svg";
import appLogo from "../../resources/logo-yhr.svg";
const Logo = ({ clientRef, className }) => {
  if (clientRef === "admin" || clientRef === "app") {
    const thisLogo = clientRef === "admin" ? adminLogo : appLogo;
    return (
      <img src={thisLogo} alt="YourHR.Space" className="logo" width="300" />
    );
  }
  const clientDetails = localStorage.getItem("clientDetails")
    ? JSON.parse(localStorage.getItem("clientDetails"))
    : null;
  const clientThemeLogo = clientDetails?.theme?.logo;
  const logo = clientThemeLogo
    ? clientThemeLogo
    : clientRef
    ? `${process.env.API_URL}/logo/${clientRef}`
    : defaultLogo;
  const logoClassName = "logo" + (className ? ` ${className}` : "");

  return (
    <img src={logo} alt="YourHR.Space" className={logoClassName} width="300" />
  );
};

export default Logo;
