import { useNavigate } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";
import useStore from "./useStore";

const useCustomNavigate = () => {
  // const setPathParts = useStore((state) => state.setPathParts);
  const hasUnsavedChanges = useStore((state) => state.hasUnsavedChanges);
  const setHasUnsavedChanges = useStore((state) => state.setHasUnsavedChanges);
  const navigate = useNavigate();

  const doNavigate = (path) => {
    setHasUnsavedChanges(false);
    // setPathParts(path?.split("/")?.filter(Boolean) || []);
    navigate(path);
  };

  // Intercept navigation with prompt
  const doCheck = (path) => {
    // If there are no unsaved changes, navigate
    if (!hasUnsavedChanges) {
      return doNavigate(path);
    }
    confirmDialog({
      message: "You have unsaved changes. Are you sure you want to proceed?",
      accept: () => {
        doNavigate(path);
      },
      className: "confirm-dialog",
      acceptClassName: "button",
      rejectClassName: "button button__invert",
    });
  };

  return doCheck;
};

export default useCustomNavigate;
