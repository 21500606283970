import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Question } from "akar-icons";
import TipMessage from "./components/common/tipMessage";
import useCustomNavigate from "./components/hooks/useCustomNavigate";
import SubmitButton from "./components/common/submitButton";
import Logo from "./components/logo";
import Error from "./components/common/error";
import Throbber from "./components/common/throbber";
import ModalContent from "./components/common/modalContent";

import { useFetchAccount } from "./components/hooks/fetch/useFetchAccounts";
import { useFetchAdminConfig } from "./components/hooks/fetch/useFetchAdminConfig";
import useStore from "./components/hooks/useStore";

const Login = ({ onLogin }) => {
  const navigate = useCustomNavigate();
  const fingerprint = useStore((state) => state.fingerprint);
  const subdomain = useStore((state) => state.subdomain);
  const initClientRef = useStore((state) => state.subdomain);
  const setClientRef = useStore((state) => state.setClientRef);
  const clientDetails = useStore((state) => state.clientDetails);

  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [slideClass, setSlideClass] = useState(false);
  const [loginUsername, setLoginUsername] = useState(null);
  const [loginPassword, setLoginPassword] = useState(null);
  const [loginTwoFactorCode, setLoginTwoFactorCode] = useState(null);
  const [loginEmail, setLoginEmail] = useState(null);
  const [loginTwoFactorNeeded, setLoginTwoFactorNeeded] = useState(false);
  const [loginClient, setLoginClient] = useState(initClientRef);
  const [loginCompanies, setLoginCompanies] = useState(
    JSON.parse(localStorage.getItem("clientsList"))
  );
  const [helpTextTitle, setHelpTextTitle] = useState(null);
  const [helpText, setHelpText] = useState(null);

  const { data: config, isLoading: isConfigLoading } = useFetchAdminConfig({});
  useEffect(() => {
    const helpItemConfig = config?.helpText?.find(
      (helpItem) => helpItem?.title === helpTextTitle
    );
    setHelpText(helpItemConfig?.content);
  }, [config, helpTextTitle]);

  const { data: account, isLoading: isAccountLoading } = useFetchAccount({
    account: subdomain,
    retry: false,
  });
  useEffect(() => {
    if (account?.browserTitle) {
      document.title = account?.browserTitle || "YourHR.space";
    }
  }, [account]);

  const resetLoginErrorTimeout = () => {
    setTimeout(() => {
      setLoginError(null);
    }, 4000);
  };

  // authenticate login details
  const doAuthenticateLogin = (data, client = null) => {
    navigate("/");
    client = client || loginClient;
    console.log("Logging in for client: " + client);
    const postData = {
      username: data?.username,
      password: data?.password,
      fingerprint,
    };
    if (data?.twoFactorCode) {
      postData.twoFactorCode = data?.twoFactorCode;
    }
    console.log("Authenticating login for client: " + client, data, postData);
    fetch(process.env.API_URL + "/auth/" + client, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => {
        if (res.ok) {
          console.log("Login successful");
          return res.text().then((token) => {
            setLoggingIn(false);
            onLogin(true, token);
          });
        } else if (res.status === 401) {
          console.log("Login attempted - 2FA needed");
          setSlideClass(null);
          setLoginTwoFactorNeeded(true);
          setLoggingIn(false);
        } else {
          console.log("Login failed");
          return res.text().then((errMsg) => {
            if (errMsg === "Invalid username or password") {
              console.log("Invalid username or password");
              setLoginError(errMsg);
              setSlideClass(null);
            } else if (data?.twoFactorCode) {
              console.log("Invalid 2FA code");
              setLoginError("😕 The security code you entered was incorrect");
            } else {
              console.log("Cannot login", errMsg);
              setLoginError(
                "😕 Oh dear - we were unable to log you in. Have you mistyped something?"
              );
            }
            resetLoginErrorTimeout();
            setLoggingIn(false);
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoggingIn(false);
      });
  };

  // authenticate admin login details
  const doAuthenticateAdminLogin = (data) => {
    navigate("/");
    const postData = {
      username: data.username,
      password: data.password,
    };
    fetch(process.env.API_URL + "/admin/auth/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => {
        if (res.ok) {
          return res.text().then((token) => {
            setLoggingIn(false);
            onLogin(true, token, true);
          });
        } else {
          return res.text().then((errMsg) => {
            setLoggingIn(false);
            setLoginError(errMsg);
            resetLoginErrorTimeout();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoggingIn(false);
      });
  };

  // get all accounts associated with username
  const doFindCompanies = (data = {}) => {
    console.log("Finding companies for username: " + data?.username);
    fetch(process.env.API_URL + "/accounts/" + data?.username, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.text().then((accounts) => {
            const companies = JSON.parse(accounts);
            console.log("Found companies", companies?.accounts);
            if (companies?.accounts?.length === 1) {
              const foundClientRef = companies.accounts[0].clientRef;
              setClientRef(foundClientRef);
              setLoginClient(foundClientRef);
              console.log("Set client ref to " + foundClientRef);
              doAuthenticateLogin(
                {
                  username: data?.username,
                  password: data?.password,
                  twoFactorCode: data?.loginTwoFactorCode,
                },
                foundClientRef
              );
            } else if (companies?.accounts?.length > 1) {
              setLoggingIn(false);
              console.log("Multiple companies found - user to enter email");
              setSlideClass("login-container--slides__slide2");
            } else {
              console.log("No companies found");
              setLoginError(
                "😕 Oh dear - we were unable to find your account. Have you mistyped something?"
              );
              resetLoginErrorTimeout();
              setLoggingIn(false);
            }
          });
        } else {
          return res.text().then((errMsg) => {
            console.log(err);
            setLoggingIn(false);
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoggingIn(false);
      });
  };

  // when the login button is clicked...
  const doLogin = (data = {}) => {
    console.log("Log in clicked", data, loginClient);
    setLoginUsername(data?.username);
    setLoginPassword(data?.password);
    setLoginTwoFactorCode(data?.twoFactorCode);
    if (!data?.clientRef && loginClient && loginClient !== "app") {
      console.log("Setting client ref to " + loginClient);
      data.clientRef = loginClient;
    }

    // already logging in? return
    if (loggingIn || !data?.username || !data?.password) return null;
    // set logging in to true
    setLoggingIn(true);

    // if subdomain is admin, authenticate admin login
    if (subdomain === "admin") {
      console.log("Admin login");
      return doAuthenticateAdminLogin(data);
    }

    // if subdomain is app, find companies for username
    if (subdomain === "app") {
      console.log("App login");
      if (data?.clientRef) {
        console.log("Client ref specified by user: " + data?.clientRef);
        setClientRef(data?.clientRef);
        return doLoginClient(data?.clientRef, data);
      }
      return doFindCompanies(data);
    }

    // if we've got a client ref from URL, authenticate login
    if (initClientRef) {
      console.log("Login for subdomain: " + initClientRef);
      return doAuthenticateLogin(data);
    }

    // if we've got a client ref from login, authenticate login
    if (data?.clientRef) {
      console.log("Login for client: " + data?.clientRef);
      doLoginClient(data?.clientRef, data);
    }
  };

  // for app - if multiple companies found, narrow down using email
  const doLoginEmail = (data) => {
    console.log(
      "Logging in with email: " + data.email + " and username: " + loginUsername
    );
    setLoggingIn(true);
    setLoginEmail(data.email);
    fetch(
      process.env.API_URL +
        "/accounts/" +
        loginUsername +
        "?email=" +
        data.email,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.text().then((accounts) => {
            const companies = JSON.parse(accounts);
            if (companies?.accounts?.length === 1) {
              const foundClientRef = companies.accounts[0].clientRef;
              console.log("Found company: " + foundClientRef);
              localStorage.setItem("clientRef", foundClientRef);
              setLoginClient(foundClientRef);
              doAuthenticateLogin(
                {
                  username: loginUsername,
                  password: loginPassword,
                  twoFactorCode: loginTwoFactorCode,
                },
                foundClientRef
              );
            } else if (companies?.accounts?.length > 1) {
              console.log(
                "Multiple companies found - user to select from list",
                companies?.accounts
              );
              setLoggingIn(false);
              const companiesList = JSON.stringify(companies?.accounts);
              localStorage.setItem("clientsList", companiesList);
              setLoginCompanies(companies?.accounts);
              setSlideClass("login-container--slides__slide3");
            } else {
              console.log("No companies found");
              setLoginError(
                "😕 Oh dear - we were unable to find your account. Have you mistyped something?"
              );
              resetLoginErrorTimeout();
              setLoggingIn(false);
            }
          });
        } else {
          return res.text().then((errMsg) => {
            console.log(err);
            setLoggingIn(false);
          });
        }
      })
      .catch((err) => {
        setLoggingIn(true);
        console.log(err);
        setLoggingIn(false);
      });
  };

  const doLoginClient = (clientRef, data = {}) => {
    client = clientRef || loginClient;
    const twoFactorCode = data?.twoFactorCode || loginTwoFactorCode;
    const authData = {
      username: data?.username || loginUsername,
      password: data?.password || loginPassword,
    };
    if (twoFactorCode) {
      authData.twoFactorCode = twoFactorCode;
    }
    console.log(
      "Setting client and logging in for client: " + clientRef,
      authData
    );
    localStorage.setItem("clientRef", clientRef);
    setLoginClient(clientRef);
    setLoggingIn(true);
    doAuthenticateLogin(authData, clientRef);
  };

  const doResetLogin = () => {
    setSlideClass(null);
    setLoginError(null);
    setLoginTwoFactorNeeded(false);
    setLoggingIn(false);
    setLoginUsername(null);
    setLoginPassword(null);
  };

  const containerClass =
    subdomain === "admin"
      ? "login-container login-container__admin"
      : "login-container";

  const slidesClass =
    "login-container--slides" + (slideClass ? ` ${slideClass}` : "");

  const logoClient =
    loginClient === "master" && subdomain === "app" ? "app" : loginClient;

  const isAccountOK = !!(
    account ||
    clientDetails ||
    subdomain === "admin" ||
    subdomain === "app"
  );

  const isModalLoading = !!isConfigLoading;

  doShowHelp = () => {
    setHelpTextTitle("Trouble logging in");
  };

  return (
    <>
      <div className={containerClass}>
        {isAccountLoading && <Throbber />}
        {!isAccountLoading && (
          <div className="login-container--panel">
            {!isAccountOK && (
              <>
                <Logo clientRef="app" />
                <br />
                <h2>Have you mistyped your URL?</h2>
                <p>
                  <button
                    className="button-unstyled button-unstyled__link button-unstyled__flex"
                    onClick={doShowHelp}
                  >
                    <Question /> Help and information
                  </button>
                </p>
              </>
            )}
            {isAccountOK && (
              <>
                <div className="login-container--logo">
                  <Logo clientRef={logoClient} />
                </div>
                <h1 className="login-container--title">
                  Welcome to YourHR.space
                </h1>
                <div className={slidesClass}>
                  <LoginUsernamePassword
                    loggingIn={loggingIn}
                    clientsList={loginCompanies}
                    clientRef={loginClient}
                    twoFactorNeeded={loginTwoFactorNeeded}
                    showingModal={!!helpTextTitle}
                    onLogin={doLogin}
                    onShowModal={setHelpTextTitle}
                    onRestart={doResetLogin}
                  />
                  <LoginEmail
                    onLogin={doLoginEmail}
                    loggingIn={loggingIn}
                    email={loginEmail}
                  />
                  <LoginClientSelect
                    onLogin={doLoginClient}
                    loggingIn={loggingIn}
                    clientsList={loginCompanies}
                    clientRef={loginClient}
                  />
                </div>
              </>
            )}
          </div>
        )}

        {loginError && !helpTextTitle && <Error message={loginError}></Error>}
      </div>

      {helpTextTitle && (
        <ModalContent
          content={helpText}
          loading={isModalLoading}
          type={
            helpTextTitle === "Forgotten password" ? "resetPassword" : "content"
          }
          onClose={() => {
            setHelpTextTitle(null);
          }}
        />
      )}
    </>
  );
};

const LoginUsernamePassword = ({
  loggingIn,
  clientsList,
  twoFactorNeeded,
  showingModal = false,
  onShowModal,
  onLogin,
  onRestart,
}) => {
  const subdomain = useStore((state) => state.subdomain);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const clientsSelect =
    subdomain &&
    subdomain === "app" &&
    clientsList &&
    Array.isArray(clientsList) ? (
      <select
        className="login-container--input"
        disabled={loggingIn}
        placeholder="Organisation"
        aria-label="Organisation"
        name="clientRef"
        defaultValue=""
        required
        {...register("clientRef", {
          required: {
            value: false,
          },
        })}
      >
        <option value="" disabled hidden>
          Organisation:
        </option>
        {clientsList?.map((client, key) => (
          <option key={key} value={client.clientRef}>
            {client.description}
          </option>
        ))}
      </select>
    ) : null;

  return (
    <>
      <form onSubmit={handleSubmit(onLogin)} className="login-container--slide">
        <h2 className="login-container--heading">Login</h2>

        {twoFactorNeeded ? (
          <>
            <div>
              <b>You have been emailed a security code.</b>
            </div>
            <div>
              Please check your email, and enter the provided code below
            </div>
            <TipMessage
              content={errors?.twoFactorCode?.message}
              visible={!showingModal && errors?.twoFactorCode ? true : false}
            >
              <input
                className="login-container--input"
                disabled={loggingIn}
                placeholder="Enter 6-digit security code"
                type="number"
                aria-label="Please enter security code"
                autoFocus
                required
                name="twoFactorCode"
                {...register("twoFactorCode", {
                  required: {
                    value: true,
                    message:
                      "Please enter the security code as provided by email",
                  },
                })}
              />
            </TipMessage>
          </>
        ) : (
          <>
            <TipMessage
              content={errors?.username?.message}
              visible={!showingModal && errors?.username ? true : false}
            >
              <input
                className="login-container--input"
                disabled={loggingIn}
                placeholder="Username"
                type="text"
                aria-label="Username"
                autoFocus
                required
                name="username"
                {...register("username", {
                  required: {
                    value: true,
                    message: "Please enter a username",
                  },
                })}
              />
            </TipMessage>
            <TipMessage
              content={errors?.password?.message}
              visible={!showingModal && errors?.password ? true : false}
            >
              <input
                className="login-container--input"
                disabled={loggingIn}
                placeholder="Password"
                type="password"
                aria-label="Password"
                required
                name="password"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Please enter a password",
                  },
                })}
              />
            </TipMessage>
            {clientsSelect}
          </>
        )}

        <div className="login-container--buttons-container">
          <SubmitButton
            label="Sign in"
            labelDisabled="Signing in..."
            disabled={loggingIn}
            isForm={true}
            onSubmit={handleSubmit(onLogin)}
          />
          <div className="login-container--links">
            {subdomain !== "admin" && (
              <>
                {twoFactorNeeded && (
                  <button
                    onClick={onRestart}
                    type="button"
                    className="button-unstyled button-unstyled__link button-unstyled__underline"
                    aria-label="Forgot your password?"
                  >
                    Return to login screen
                  </button>
                )}

                <button
                  onClick={() => {
                    onShowModal("Forgotten password");
                  }}
                  type="button"
                  className="button-unstyled button-unstyled__link button-unstyled__underline"
                  aria-label="Forgot your password?"
                >
                  Forgot your password?
                </button>
                <button
                  onClick={() => {
                    onShowModal("Trouble logging in");
                  }}
                  type="button"
                  className="button-unstyled button-unstyled__link button-unstyled__underline"
                  aria-label="Trouble logging in?"
                >
                  Trouble logging in?
                </button>
                <button
                  onClick={() => {
                    onShowModal("Privacy policy");
                  }}
                  type="button"
                  className="button-unstyled button-unstyled__link button-unstyled__underline"
                  aria-label="Privacy policy"
                >
                  Privacy policy
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

const LoginEmail = ({ loggingIn, email, onLogin }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  return (
    <form onSubmit={handleSubmit(onLogin)} className="login-container--slide">
      <h2 className="login-container--heading">
        Please enter your e-mail address
      </h2>

      <TipMessage
        content={errors?.email?.message}
        visible={errors?.email ? true : false}
      >
        <input
          className="login-container--input"
          disabled={loggingIn}
          placeholder="myname@email.com"
          type="text"
          aria-label="Registered e-mail address"
          required
          name="email"
          defaultValue={email}
          {...register("email", {
            required: {
              value: true,
              message: "Please enter your email",
            },
          })}
        />
      </TipMessage>

      <div className="login-container--buttons-container">
        <SubmitButton
          label="Sign in"
          labelDisabled="Signing in..."
          disabled={loggingIn}
          isForm={true}
          onSubmit={handleSubmit(onLogin)}
        />
      </div>
    </form>
  );
};

const LoginClientSelect = ({ loggingIn, onLogin, clientsList, clientRef }) => {
  const clientButtons =
    clientsList && Array.isArray(clientsList) ? (
      <>
        {clientsList?.map((client, key) => (
          <SubmitButton
            key={key}
            label={client.description}
            labelDisabled="Signing in..."
            disabled={loggingIn && clientRef === client.clientRef}
            isForm={true}
            onSubmit={() => {
              onLogin(client.clientRef);
            }}
          />
        ))}
      </>
    ) : null;
  return (
    <div className="login-container--slide">
      <div className="login-container--buttons-container login-container--buttons-container__wide">
        {clientButtons}
      </div>
    </div>
  );
};

export default Login;
